import React, {useEffect, useState} from "react";
import searchImg from "../../resources/images/search.svg";
import CapButton from "../common/buttons/CapButton";
import {isAdminAsPartner, isPartner} from "../../utils/authUtils";
import {emptyString} from "../../utils/stringUtils";

export default function SearchInput({ amount = 0, allAmount = 0, label = "Показано:", onTextTyping, searchValue }) {

    const labelClassName = allAmount === amount ? "txt-search" : "txt";
    const amountData = allAmount === amount ? allAmount : (amount + "/" + allAmount);
    const isPartnerPage = isAdminAsPartner() || isPartner();
    const [value, setValue] = useState("");

    useEffect(() => {
        if (emptyString(searchValue)) {
            return;
        }
        setValue(searchValue);
    }, []);

    const handleTextTyping = (val) => {
        setValue(val);
        onTextTyping(val);
    };


    return (

        <div className="search open">
            {isPartnerPage && (
                <CapButton />
            )}
            <div className="icon-search">
                {value && ( // Показываем иконку отмены только если value не пустое
                    <div onClick={() => handleTextTyping("")} className="top-search-clear">
                        {/*<Cancel />*/}
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="20" height="20" rx="7.5" stroke="black"/>
                            <path d="M14.3535 6.35355L6.35355 14.3536" stroke="black" stroke-linecap="round"/>
                            <path d="M6.35355 6.35355L14.3535 14.3536" stroke="black" stroke-linecap="round"/>
                        </svg>

                    </div>
                )}
                {!value && ( // Показываем иконку поиска только если value пустое
                    <img src={searchImg} alt="" className="src" />
                )}
            </div>
            <input
                type="search"
                placeholder="Искать..."
                className="top-search"
                onChange={(e) => handleTextTyping(e.target.value)}
                value={value}
            />
                <div className="results">
                    <div className={labelClassName}>
                        {label}
                    </div>
                    <div className="amount">{amountData}</div>
                </div>
        </div>
    )
}
