import React, {useEffect, useState} from "react";
import {Modal, ModalHeader} from "reactstrap";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import OfferModalInput from "../../../common/inputs/OfferModalInput";
import ManagerPartnersFilter from "../filters/offers/ManagerPartnersFilter";
import ManagerSelectedOffersFilter from "../filters/offers/ManagerSelectedOffersFilter";
import {connect} from "react-redux";
import {getIsoLocalString} from "../../../../utils/dateUtils";
import HiddenFromNovicesBtnBlock from "./HiddenFromNovicesBtnBlock";
import { PERCENT, RU, VALUE, VALUE_RUB} from "../../../../utils/constants";
import DatePicker from "../../../common/pickers/DatePicker";
import {isNumberStringWithSpecifiedNumberOfDecimalPlaces} from "../../../../utils/validationUtils";
import ActionPopover from "../../../common/popovers/ActionPopover";
import {loadWithChildrenOfferIncomes} from "../../../../actions/incomesActions";
import ManagerSelectedBillingTypeFilter from "../filters/offers/ManagerSelectedBillingTypeFilter";
import {availableBillingTypesData} from "../../../../utils/offerUtils";
import {massUpdate} from "../../../../actions/managerOfferActions";

function OfferMassEditingModal({isOpened, toggle, offers, selectedOffers, onSelectOffer, selectedTab, modalDataClearTrigger, onSaveCallback,
                                   modalDataClearSelectedPartnersCounter, partners, incomesWithChildren, loadWithChildrenOfferIncomes, massUpdate}) {

    const [modalData, setModalData] = useState({
        income: "",
        incomeType: "",
        incomeFromDate: null,
        eup: "",
        term: "",
        hiddenFromNovices: {label: "Без изменений"},
        status: null,
        selectedPartners: [],
        selectedPartnerIds: [],
        selectedBillingType: null,
        hasChanges: false,
        hasIncomeChanges: false,
        hasOfferDataChanges: false,
        needToNotifyPartners: false,
        needToRecalculateStatistic: false,
        fixedIncomeScale: 2 //number of decimal places for a fixed income
    });

    const [closeAfterSave, setCloseAfterSve] = useState(false);
    const [billingTypesDropdownData, setBillingTypesDropdownData] = useState([]);
    const [selectedBillingType, setSelectedBillingType] = useState(null);
    const [selectedPartners, setSelectedPartners] = useState([]);
    const [selectedPartnerIds, setSelectedPartnerIds] = useState([]);

    useEffect(() => {
        const offerIds = selectedOffers.map(offer => offer.offerId);
        loadWithChildrenOfferIncomes(offerIds, true);
    }, []);

    useEffect(() => {
        const allOffers = selectedOffers
            .flatMap(offer => [offer, ...(offer.children || [])])
            .map(offer => {return {offerId: (offer.offerId || offer.id), billingType: offer.billingType}});
        const billingTypesDropdownData = availableBillingTypesData.map(item => {return {...item,
            offerIds: allOffers.filter(offer => offer.billingType === item.type).map(offer => offer.offerId)}});
        setBillingTypesDropdownData(billingTypesDropdownData);
        if (!selectedBillingType) {
            setSelectedBillingType(billingTypesDropdownData[0]);
        }

    }, [selectedOffers]);

    useEffect(() => {
        setModalData({
            income: "",
            incomeType: "",
            incomeFromDate: null,
            eup: "",
            term: "",
            hiddenFromNovices: {label: "Без изменений"},
            status: null,
            selectedPartners: [],
            selectedPartnerIds: [],
            hasChanges: false,
            hasIncomeChanges: false,
            hasOfferDataChanges: false,
            needToNotifyPartners: false,
            needToRecalculateStatistic: false,
            fixedIncomeScale: 2
        });
    }, [modalDataClearTrigger]);

    useEffect(() => {
        setModalData({...modalData, selectedPartners: [], selectedPartnerIds: []})
    }, [modalDataClearSelectedPartnersCounter]);


    useEffect(() => {
        const partnerIds = selectedPartners.flatMap(partner => {
            if (partner.isGroup) {
                const groupData = partners.find(group => group.partnerTypeId === partner.partnerTypeId).options;
                return groupData.map(partner => partner.id);
            } else {
                return [partner.id];
            }
        });
        setSelectedPartnerIds(partnerIds);
    }, [selectedPartners]);


    useEffect(() => {
        if (!incomesWithChildren || incomesWithChildren.length === 0 || !selectedBillingType) {
            return;
        }
        let uniqueIncomes;
        let uniqueIncomeTypes;
        let uniqueIncomeFromDates;
        let hasPartnersWithoutIncomes = false;
        const selectedOfferIds = selectedBillingType?.offerIds || [];
        if (selectedPartnerIds && selectedPartnerIds.length > 0) {
            const individualIncomes = incomesWithChildren
                    .filter(income => selectedOfferIds.includes(income.offerId) && selectedPartnerIds.includes(income.partnerId));
            if (individualIncomes) {
                const incomePartnerIds = individualIncomes.map(income => income.partnerId);
                hasPartnersWithoutIncomes = !selectedPartnerIds.every(partnerId => incomePartnerIds.includes(partnerId));
            }
            uniqueIncomes = [...new Set(individualIncomes.map(income => income.income))];
            uniqueIncomeTypes = [...new Set(individualIncomes.map(income => income.incomeType))];
            uniqueIncomeFromDates = [...new Set(individualIncomes.map(income => income.fromDate))];
        } else {
            const publicIncomes = incomesWithChildren?.filter(income => !income.partnerId && selectedOfferIds.includes(income.offerId));
            uniqueIncomes = [...new Set(publicIncomes.map(income => income.income))];
            uniqueIncomeTypes = [...new Set(publicIncomes.map(income => income.incomeType))];
            uniqueIncomeFromDates = [...new Set(publicIncomes.map(income => income.fromDate))];
        }
        let income = "";
        let incomeType = "";
        let hasIncomeChanges = modalData.hasIncomeChanges;
        if (uniqueIncomes.length === 1 && uniqueIncomeTypes.length === 1 && !hasPartnersWithoutIncomes) {
            income = uniqueIncomes[0].replace(/\./, ',');
            incomeType = uniqueIncomeTypes[0];
            hasIncomeChanges = false;
        }
        const uniqueTerms = [...new Set(selectedOffers.map(offer => offer.term))];
        const uniqueEup = [...new Set(selectedOffers.map(offer => offer.eup))];
        const hasRusOffers = selectedOffers.find(offer => offer.countryType === RU);
        setModalData({...modalData,
            hasChanges: false,
            income: income,
            incomeType: incomeType,
            incomeFromDate: income && income !== "" && uniqueIncomeFromDates.length === 1 ? uniqueIncomeFromDates[0] : null,
            hasIncomeChanges: hasIncomeChanges,
            term: uniqueTerms.length === 1 ? uniqueTerms[0] : "",
            eup: uniqueEup.length === 1 ? uniqueEup[0].replace(/\./, ',') : "",
            incomeInRublesInputDisabled: hasRusOffers,
            fixedIncomeScale: hasRusOffers ? 2 : 4,
        })
    }, [selectedOffers, selectedPartnerIds, incomesWithChildren, selectedBillingType]);


    const save = () => {

        if (!modalData.hasChanges || !selectedOffers || selectedOffers.length === 0) {
            return;
        }
        const body = {};
        body.offerIds = selectedBillingType.offerIds;
        body.partnerIds = selectedPartnerIds;
        body.status = modalData.status;
        const isIncomeNotNull = modalData.hasIncomeChanges && modalData.income !== null && modalData.income !== '';
        body.income = isIncomeNotNull ? modalData.income.replace(/,/, '.') : null;
        body.incomeType = modalData.hasIncomeChanges && modalData.incomeType !== null && modalData.incomeType !== '' ? modalData.incomeType : null;
        body.incomeFromDate = isIncomeNotNull ? modalData.incomeFromDate : null;
        body.hiddenFromNovices = !modalData.hiddenFromNovices || modalData.hiddenFromNovices.value === null ? null : modalData.hiddenFromNovices.value;
        body.term = modalData.term !== null && modalData.term !== '' ? modalData.term : null;
        body.eup = modalData.eup !== null && modalData.eup !== '' ? modalData.eup.replace(/,/, '.') : null;
        body.needToNotifyPartners = modalData.needToNotifyPartners;
        body.needToRecalculateStatistic = modalData.needToRecalculateStatistic;

        const parentOfferIds = selectedOffers.map(offer => offer.offerId);
        massUpdate(body, () => {
            loadWithChildrenOfferIncomes(parentOfferIds);
            onSaveCallback(closeAfterSave);
        });

    };



    const onTermTyping = (e) => {
        setModalData({...modalData,
            hasChanges: true,
            hasOfferDataChanges: true,
            term: e.target.value,
        })
    };

    const onIncomeTyping = (e, type) => {
        const incomeString = e.target.value;
        const scale = modalData.fixedIncomeScale;
        if (incomeString !== "" && !isNumberStringWithSpecifiedNumberOfDecimalPlaces(incomeString, scale)) {
            return;
        }
        setModalData({...modalData,
            income: incomeString,
            incomeType: (incomeString === "") ? "" : type,
            hasChanges: true,
            hasIncomeChanges: true,
        });
    };

    const onNumberTyping = (e, fieldName) => {
        if (e.target.value !== "" && !e.target.value.match(/^[+-]?\d+(,)?(\d{1,2})?$/)) {
            return;
        }
        setModalData({...modalData,
            [fieldName]: e.target.value,
            hasChanges: true,
            hasOfferDataChanges: true,
        });
    };

    const onSelectHiddenFromNovices = (value) => {
        setModalData({...modalData,
            hiddenFromNovices: value,
            hasChanges: true,
            hasOfferDataChanges: true,
        });
    };

    const onToggleDisabled = () => {
        if (modalData.status === null) {
            switch (selectedTab) {
                case "ACTIVE_OFFERS":
                    setModalData({...modalData,
                        status: "INACTIVE",
                        hasChanges: true,
                        hasOfferDataChanges: true,
                    });
                    break;
                case "INACTIVE_OFFERS":
                case "DEPRECATED_OFFERS":
                    setModalData({...modalData,
                        status: "ACTIVE",
                        hasChanges: true,
                        hasOfferDataChanges: true,
                    });
            }
        } else {
            setModalData({...modalData,
                status: null,
                hasChanges: true,
                hasOfferDataChanges: true,
            });
        }
    };

    const onSelectPartners = (selectedPartners) => {
        setSelectedPartners(selectedPartners);
    };

    const onSelectBillingType = (billingType) => {
        setSelectedBillingType(billingType);
    };

    const onToggleNeedToNotifyPartners = () => {
        setModalData({...modalData,
            needToNotifyPartners: !modalData.needToNotifyPartners,
        });
    };

    const onToggleNeedToRecalculateStatistic = () => {
        setModalData({...modalData,
            needToRecalculateStatistic: !modalData.needToRecalculateStatistic,
        });
    };

    const onSelectIncomeFromDate = (date) => {
        const hasChanges = modalData.income && modalData.income !== "";
        setModalData({...modalData,
            incomeFromDate: getIsoLocalString(date),
            hasChanges: hasChanges,
            hasIncomeChanges: hasChanges,
        });
    };

    const getCheckboxLabel = () => {
        return selectedTab === "ACTIVE_OFFERS" ? "Отключить оффер" : "Активировать оффер"
    };

    const incomeInPercent = () => {
        return modalData.incomeType === PERCENT && modalData.income ? modalData.income : "";
    };

    const incomeInValue = () => {
        return modalData.incomeType === VALUE && modalData.income ? modalData.income : "";
    };

    const incomeInValueRub = () => {
        return modalData.incomeType === VALUE_RUB && modalData.income ? modalData.income : "";
    };

    const getConfirmMessage = () => {
        const {incomeFromDate, eup, term, hiddenFromNovices, status, hasIncomeChanges,
            hasOfferDataChanges, needToNotifyPartners, needToRecalculateStatistic} = modalData;
        const offerIds = selectedOffers.map(offer => offer.offerId);
        const incomePercent = incomeInPercent();
        const incomeValue = incomeInValue();
        const incomeValueRub = incomeInValueRub();
        let message = <div style={{fontSize: "14px"}}>
            <h5>Подтвердите сохранение данных:</h5>
            <div> Офферы: {offerIds.toString()}</div>
            <div> Тип выплаты: {selectedBillingType?.fullName}</div>
            <div> Партнёры: {selectedPartnerIds?.length > 0 ? selectedPartnerIds.toString() : "-"}</div>
            {hasOfferDataChanges && <>
            <div> Скрыть от новичков: {hiddenFromNovices.label}</div>
            <div> Статус оффера: {status ? status : "Без изменений"}</div>
            <div> EUP: {eup ? eup : "-"}</div>
            <div> Ограничения/таргетинг: {term ? term : "-"}</div>
                </>}
            {hasIncomeChanges && <>
                 <div> Ставка, %: {incomePercent && incomePercent !== "" ? incomePercent : "-"}</div>
                 <div> Ставка фикс в нац валюте: {incomeValue && incomeValue !== "" ? incomeValue : "-"}</div>
                {modalData.incomeType === VALUE_RUB && <div> Ставка фикс в рублях: {incomeValueRub && incomeValueRub !== "" ? incomeValueRub : "-"}</div>}
                 <div> Дата начала действия ставки: {incomeFromDate ? incomeFromDate : "-"}</div>
                <div> Пересчитать статистику за сегодняшний день: {needToRecalculateStatistic ? "ДА" : "НЕТ"}</div>
                <div> Оповестить партнеров об изменении ставок: {needToNotifyPartners ? "ДА" : "НЕТ"}</div>
             </>}
        </div>;
        return message;
    };

    return (
        <Modal className="ModalMassEdit" size="lg" isOpen={isOpened} toggle={() => toggle()}>
            <ModalHeader style={{float: "left"}} toggle={() => toggle()}>Массовое
                редактирование</ModalHeader>
            <ModalBody>
                <div className="col ramka">
                    <div className="mr-2 flex-start modal-grid" id="notific2">
                        <ManagerSelectedOffersFilter offers={offers} selectedOffers={selectedOffers}
                                                     onSelectOffer={onSelectOffer}/>
                        <ManagerPartnersFilter partners={partners} selectPartners={onSelectPartners}
                                               selectedPartners={selectedPartners}/>
                        <ManagerSelectedBillingTypeFilter selectedBillingType={selectedBillingType} onSelectBillingType={onSelectBillingType} dropdownData={billingTypesDropdownData}/>

                        <div className="modal50">
                            <HiddenFromNovicesBtnBlock selectedValue={modalData.hiddenFromNovices}
                                                       onSelect={onSelectHiddenFromNovices}/>
                        </div>
                        <div className="modal50">
                            <div className="cb-block">
                                <IosCheckbox isChecked={modalData.status ? modalData.status : false} label={getCheckboxLabel()}
                                             labelClassName="float-left"
                                             onClickFunction={onToggleDisabled}/>
                            </div>
                        </div>

                        <OfferModalInput name="eup" label="EUP" onTextTyping={(e) => onNumberTyping(e, "eup")} value={modalData.eup}
                                         dataType="text"/>
                        <OfferModalInput name="incomeInPercent" label="Ставка %"
                                         onTextTyping={(e) => onIncomeTyping(e, PERCENT)}
                                         value={incomeInPercent()} dataType="text"
                                         readOnly={modalData.incomeType === VALUE || modalData.incomeType === VALUE_RUB}/>
                        <OfferModalInput name="incomeInValue" label="Ставка фикс в нац валюте"
                                         onTextTyping={(e) => onIncomeTyping(e, VALUE)}
                                         value={incomeInValue()} dataType="text"
                                         readOnly={modalData.incomeType === PERCENT || modalData.incomeType === VALUE_RUB}/>
                        {!modalData.incomeInRublesInputDisabled && <OfferModalInput name="incomeInValue" label="Ставка фикс в рублях"
                                         onTextTyping={(e) => onIncomeTyping(e, VALUE_RUB)}
                                         value={incomeInValueRub()} dataType="text"
                                         readOnly={modalData.incomeType === PERCENT || modalData.incomeType === VALUE}/> }
                        <div className="col" style={{padding: "0 10px 0 0"}}>
                            <label className="float-left">Дата начала действия ставки</label>
                            <DatePicker inputType="simple"
                                        date={modalData.incomeFromDate && new Date(modalData.incomeFromDate)} autoClose
                                        onSelectDate={onSelectIncomeFromDate} minDate={new Date()}/>
                        </div>
                        <OfferModalInput className="form-group flex-start last-mb" name="term"
                                         label="Ограничения/Таргетинг"
                                         onTextTyping={onTermTyping} value={modalData.term} dataType="text"/>

                        <div className="form-group flex-start  last-mb fw">
                            <div className="mr-2 flex-start modal-grid">
                                <div className="modal50">
                                    <IosCheckbox isChecked={modalData.needToRecalculateStatistic}
                                                 label="Пересчитать статистику за сегодняшний день"
                                                 labelClassName="float-left"
                                                 onClickFunction={onToggleNeedToRecalculateStatistic}
                                                 labelStyle={{marginRight: "50px"}}/>
                                </div>
                                <div className="modal50">
                                    <IosCheckbox isChecked={modalData.needToNotifyPartners}
                                                 label="Оповестить партнеров об изменении ставок "
                                                 labelClassName="float-left"
                                                 onClickFunction={onToggleNeedToNotifyPartners}
                                                 labelStyle={{marginRight: "58px"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="form-group last-mb">
                    <div className="text-right">
                        <ActionPopover
                            button={
                                    <>
                                        <button disabled={!modalData.hasChanges} className="btn btn-outline-secondary"
                                                style={{marginRight: "5px"}} onClick={() => setCloseAfterSve(false)}>
                                            Сохранить и продолжить
                                        </button>
                                        <button disabled={!modalData.hasChanges} className="btn btn-outline-info"
                                                onClick={() => setCloseAfterSve(true)}>
                                            Сохранить
                                        </button>
                                    </>
                                    }
                            tooltip={"Заказать выплату"}
                            popoverLocation='top'
                            label={getConfirmMessage()}
                            handleSubmit={() => save()}/>
                    </div>
                    <hr/>
                </div>
            </ModalFooter>

        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        incomesWithChildren: state.offerIncomeReducer.incomesWithChildren,
        partners: state.managerPartnersReducer.partnersFilterData,
    }
};

export default connect(mapStateToProps, {
   loadWithChildrenOfferIncomes, massUpdate,
})(OfferMassEditingModal);