import React from 'react';
import PropTypes from "prop-types";
import image_not_available from "../../../../resources/images/image-not-available.png";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import {availableBillingTypesData, getIncome, getOfferNameWithId} from "../../../../utils/offerUtils";
import {isMobileScreen} from "../../../../utils/displayUtils";
import OperatorImage from "../../../common/images/OperatorImage";
import {getPartnerId, isAdminAsPartner, isPartner} from "../../../../utils/authUtils";
import {useHistory} from 'react-router-dom';
import {URL_OFFER_ITEM} from "../../../../properties";


OfferCard.propTypes = {
    offer: PropTypes.shape({
        offerId: PropTypes.any.isRequired,
        adultContent: PropTypes.bool.isRequired,
        category: PropTypes.shape({name: PropTypes.string}).isRequired,
        name: PropTypes.string.isRequired,
        imgUrl: PropTypes.string,
        term: PropTypes.string,
        operatorType: PropTypes.string,
        countryType: PropTypes.string,
        isGray: PropTypes.bool,
    }),
    isChecked: PropTypes.bool.isRequired,
};


export default function OfferCard(props) {

    const {offer, offerIncomes, onSelect, isChecked, isGray, img} = props;
    const history = useHistory();

    const isMobile = isMobileScreen();

    const handleOfferNmeClick = () => {
        if (isPartner() || isAdminAsPartner()) {
            const href = URL_OFFER_ITEM + '/' + offer.offerId + (isAdminAsPartner() ? ("?partnerId=" + getPartnerId()) : "");
            history.push(href);
        }
    };


    const getImage = () => {
        return img ? img : offer.imgUrl ? offer.imgUrl : image_not_available;
    };

    const renderIncomes = (incomes) => {
        return <>
            <div className="offer-info-item">
            {
                availableBillingTypesData.map(billingTypeItem => <span key={billingTypeItem.shortName}>{billingTypeItem.shortName}</span>)
            }
            </div>
            <div className="offer-info-item font-14 font-weight-bold">
            {
                availableBillingTypesData.map(billingTypeItem => {
                    const income = incomes?.find(income => income.billingType === billingTypeItem.type);
                    return <span key={billingTypeItem.shortName}>{getIncome(income, offer)}</span>
                })
            }
            </div>
        </>
    };

    const content = (
        <>
            <IosCheckbox isChecked={isChecked} onClickFunction={() => !isMobile && onSelect && onSelect(offer)}/>
            {offer.newOffer && <div className="newoffer" onClick={() => !isMobile && onSelect && onSelect(offer)}>NEW</div>}
            <div className="oss-icon ofcard">
                <OperatorImage type={offer.operatorType ? offer.operatorType : offer.countryType}/>
            </div>
            <div className={offer.category.name.toLowerCase().includes("адалт") ? "adult" : "non-adult"}>{offer.category.name}</div>
            <div className="sound"/>
            <div className="offer-title" onClick={handleOfferNmeClick}>{getOfferNameWithId(offer.offerId, offer.name)}</div>

            <div className="screen cont">
                <img src={getImage()} alt=""
                     className="offer-image"/>
                <div className="shtorka cont2">
                    <div className="restriction-text">{offer.term}</div>
                </div>
            </div>
            <div className="offer-info">
                {renderIncomes(offerIncomes)}
            </div>
        </>
    );

    return (
        < div className={offer.hidden ? "offer-block noBet" : "offer-block"} onClick={() => onSelect && onSelect(offer)}>
            <label className="label-checkbox" >
                <div className={isGray ? "offer-container hvr-reveal oldGray" : "offer-container hvr-reveal"}
                     onClick={() => isMobile && onSelect && onSelect(offer)}>
                    {offer.recommend ? <div className="hotoffer">{content}</div> : content}
                </div>
            </label>
        </div>
    );
}
