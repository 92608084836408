import {
    BUYOUTS,
    CLICKS,
    GROSS_REVENUE,
    INCOME,
    INCOME_MINUS_OPERATORS_REVENUE,
    MARGIN, PAYMENTS_AMOUNT, ROYALTIES_INCOME,
    SUBSCRIPTIONS,
    TRAFFICBACKS, CVR, LEADS, MANAGER_ANALYSIS, PARTNER_ANALYSIS, MANAGER_MAIN, PARTNER_MAIN
} from "./constants";
import {isAdminAsPartner, isPartner} from "./authUtils";


export const borderColors = [
    '#b53930',
    '#55ae95',
    '#537ec5',
    '#f39422',
    '#fd7792',
    '#64ccda',
    '#ffac8e',
    '#00909e',
    '#71a95a',
    '#745c97',
    '#0b3904',
    '#a11c17',
    '#93643e',
    '#123054',
    '#585d38',
    '#fa5b17',
    '#7d6c5d',
    '#7a0f64',
    '#53e282',
    '#f02478',
    '#573045',
    '#1542e2',
    '#a96de2',
];

const chartLinesDispatcher = {
    clicks: {
        label: 'Клики', backgroundColor: borderColors[0], borderColor: borderColors[0], pointBorderColor: borderColors[0],
    },
    subscriptions: {
        label: 'Подписки', backgroundColor: borderColors[1], borderColor: borderColors[1], pointBorderColor: borderColors[1],
    },
    rebills: {
        label: 'Ребиллы', backgroundColor: borderColors[2], borderColor: borderColors[2], pointBorderColor: borderColors[2],
    },
    trafficbacks: {
        label: 'Trafficback', backgroundColor: borderColors[3], borderColor: borderColors[3], pointBorderColor: borderColors[3],
    },
    cvr: {
        label: 'CR, %', backgroundColor: borderColors[4], borderColor: borderColors[4], pointBorderColor: borderColors[4],
    },
    iClicks: {
        label: 'ИК', backgroundColor: borderColors[5], borderColor: borderColors[5], pointBorderColor: borderColors[5],
    },
    buyouts: {
        label: 'Выкупы', backgroundColor: borderColors[6], borderColor: borderColors[6], pointBorderColor: borderColors[6],
    },
    activeAmount: {
        label: 'Активные', backgroundColor: borderColors[7], borderColor: borderColors[7], pointBorderColor: borderColors[7],
    },
    unsubscribes: {
        label: 'Отписки', backgroundColor: borderColors[8], borderColor: borderColors[8], pointBorderColor: borderColors[8],
    },
    income: {
        label: 'Выплаты', backgroundColor: borderColors[9], borderColor: borderColors[9], pointBorderColor: borderColors[9],
        labelForPartner: 'Доход, руб',
    },
    incomePayed: {
        label: 'Выплачено, руб', backgroundColor: borderColors[2], borderColor: borderColors[2], pointBorderColor: borderColors[2],
    },
    incomePaidApproved: {
        label: 'Выплачено, руб', backgroundColor: borderColors[2], borderColor: borderColors[2], pointBorderColor: borderColors[2],
    },
    incomePaidInRub: {
        label: 'Сумма, руб', backgroundColor: borderColors[2], borderColor: borderColors[2], pointBorderColor: borderColors[2],
    },
    leads: {
        label: 'Лиды', backgroundColor: borderColors[2], borderColor: borderColors[2], pointBorderColor: borderColors[2],
    },
    paymentsAmount: {
        label: 'Платежи', backgroundColor: borderColors[10], borderColor: borderColors[10], pointBorderColor: borderColors[10],
    },
    partnersPaymentsAmount: {
        label: 'Платежи партнеру', backgroundColor: borderColors[11], borderColor: borderColors[11], pointBorderColor: borderColors[11],
    },
    price: {
        label: 'Цена', backgroundColor: borderColors[12], borderColor: borderColors[12], pointBorderColor: borderColors[12],
    },
    operatorDeductionKoeff: {
        label: 'Доля AM', backgroundColor: borderColors[13], borderColor: borderColors[13], pointBorderColor: borderColors[13],
    },
    operatorKoeff: {
        label: '% Сверки', backgroundColor: borderColors[14], borderColor: borderColors[14], pointBorderColor: borderColors[14],
    },
    partnerKoeff: {
        label: '% Выплат', backgroundColor: borderColors[15], borderColor: borderColors[15], pointBorderColor: borderColors[15],
    },
    royaltiesKoeff: {
        label: '% Роялти', backgroundColor: borderColors[16], borderColor: borderColors[16], pointBorderColor: borderColors[16],
    },
    grossRevenue: {
        label: 'Gross EUP', backgroundColor: borderColors[17], borderColor: borderColors[17], pointBorderColor: borderColors[17],
    },
    incomeMinusOperatorsRevenue: {
        label: 'Выручка', backgroundColor: borderColors[18], borderColor: borderColors[18], pointBorderColor: borderColors[18],
    },
    referrerIncome: {
        label: 'Рефералы', backgroundColor: borderColors[19], borderColor: borderColors[19], pointBorderColor: borderColors[19],
    },
    royaltiesIncome: {
        label: 'Роялти', backgroundColor: borderColors[20], borderColor: borderColors[20], pointBorderColor: borderColors[20],
    },
    margin: {
        label: 'Margin', backgroundColor: borderColors[21], borderColor: borderColors[21], pointBorderColor: borderColors[21],
    },
    percentageOfUnsubscribes: {
        label: '% Отписок', backgroundColor: borderColors[22], borderColor: borderColors[22], pointBorderColor: borderColors[22],
    },
};

export const getDataset = (lineId, lineData, idx, isLoggedAsPartner, showGroupLineChart) => {

    let dataset = chartLinesDispatcher[lineId];
    if (!dataset) {
         dataset = {
             label: lineId,
             backgroundColor: borderColors[idx],
             borderColor: borderColors[idx],
             pointBorderColor: borderColors[idx],
         };
    } else if (isLoggedAsPartner && dataset.labelForPartner) {
        dataset.label = dataset.labelForPartner;
    }
    dataset.yAxisID = showGroupLineChart ? "default" : lineId;
    dataset.data = lineData;
    return dataset;
};

export const getLineLabel = (lineName, isLoggedAsPartner) => {
    if (!chartLinesDispatcher[lineName])
        return lineName;
    return isLoggedAsPartner && chartLinesDispatcher[lineName].labelForPartner ? chartLinesDispatcher[lineName].labelForPartner : chartLinesDispatcher[lineName].label;
};

export const getLineColor = (lineName, idx) => {
    return lineName && chartLinesDispatcher[lineName] ? chartLinesDispatcher[lineName].borderColor : borderColors[idx];
};

export const analysisChartTypes = [
    {label: "Лиды", value: LEADS},
    {label: "Клики", value: CLICKS},
    {label: "CR, %", value: CVR, hideIfDonut: true},
    {label: "Trafficback", value: TRAFFICBACKS},
];

export const groupChartPartnerTypes = [
    {label: "Доход", value: INCOME},
    {label: "Подписки", value: SUBSCRIPTIONS},
    {label: "Выкупы", value: BUYOUTS},
    {label: "Клики", value: CLICKS},
    {label: "CR, %", value: CVR, hideIfDonut: true},
    {label: "Trafficback", value: TRAFFICBACKS},
];

export const groupChartManagerTypes = [
    {label: "Подписки", value: SUBSCRIPTIONS},
    {label: "Margin", value: MARGIN},
    {label: "Выкупы", value: BUYOUTS},
    {label: "CR, %", value: CVR, hideIfDonut: true},
    {label: "Ребилы", value: PAYMENTS_AMOUNT},
    {label: "Gross EUP", value: GROSS_REVENUE},
    {label: "Выручка", value: INCOME_MINUS_OPERATORS_REVENUE},
    {label: "Выплаты", value: INCOME},
    {label: "Роялти", value: ROYALTIES_INCOME},
    {label: "Клики", value: CLICKS},
    {label: "Trafficback", value: TRAFFICBACKS},
];

export const getGroupChartDataType = (statisticType) => {
    switch (statisticType) {
        case MANAGER_ANALYSIS:
        case PARTNER_ANALYSIS:
            return analysisChartTypes;
        case MANAGER_MAIN:
            return groupChartManagerTypes;
        case PARTNER_MAIN:
            return groupChartPartnerTypes;
    }
    return isPartner() || isAdminAsPartner() ? groupChartPartnerTypes : groupChartManagerTypes;
};

export const getDefaultGroupChartDataType = (statisticType = null) => {
    return getGroupChartDataType(statisticType)[0];
}