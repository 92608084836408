import React, {useEffect, useState} from "react";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import SimpleTable from "../../../common/tables/SimpleTable";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {connect, useSelector} from "react-redux";
import {ASC, PERCENT} from "../../../../utils/constants";
import {
    getIsoLocalString,
    getServerCurrentDay
} from "../../../../utils/dateUtils";


import {searchByString} from "../../../../utils/searchUtils";
import useResizeObserver from "../../../../hooks/useResizeObserver";
import OfferEditIncomePopover from "./OfferEditIncomePopover";
import {availableBillingTypesData, NEW_OFFER_IDS} from "../../../../utils/offerUtils";

import {
    deleteIncome,
    loadWithChildrenOfferIncomes,
} from "../../../../actions/incomesActions";
import OfferEditCloneIncomesPopover from "./OfferEditCloneIncomesPopover";
import {
    getFixedIncomeString,
    getPercentIncomeString,
    incomeTableColumnFormatters,
    isFixedIncomeType
} from "../../../../utils/incomeUtils";

function OfferEditIncomesData({pageState, deleteIncome, excludeClonedIncome,
                                sortableIncomeColumn, sortableIncomeDirection, setIncomeSorting,
                                  searchValue, setFilteredIncomesAmount, currencyRate,
                                  loadWithChildrenOfferIncomes, incomesWithChildren}) {


    const {offer, children} = pageState;
    const [isOnlyActualIncomes, setIsOnlyActualIncomes] = useState(true);
    const [partners, setPartners] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [filteredIncomes, setFilteredIncomes] = useState([]);
    const partnersFilterData = useSelector(state => state.managerPartnersReducer.partnersFilterData);

    const [offersDropdownData, setOffersDropdownData] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState([]);

    const currentDate = getIsoLocalString(getServerCurrentDay());


    useEffect(() => {
        loadWithChildrenOfferIncomes([offer.offerId]);
    }, []);

    useEffect(() => {
        const partnersData = partnersFilterData.reduce((prev, curr) => {
            return [...prev, ...curr.options]
        }, []);
        partnersData.sort((a, b) => a.partnerName.localeCompare(b.partnerName));
        setPartners(partnersData);
    }, [partnersFilterData]);

    useEffect(() => {
        if (!incomesWithChildren || incomesWithChildren.length === 0) {
            return;
        }
        let incomes = incomesWithChildren.map(income => {return {...income,
            offerId: income.offerId,
            partnerName: (income.isPersonal ? income.partnerName : "ОБЩАЯ"),
            income: getFixedIncomeString(income.income, income.incomeType, offer.eup, currencyRate),
            incomeInPercent: getPercentIncomeString(income.income, income.incomeType, offer.eup, currencyRate),
            incomeType: income.incomeType,
            fromDate: income.fromDate,
            toDate: income.toDate,
            clonedIncome: true,
        }});
        setIncomes(incomes);
    }, [incomesWithChildren, currencyRate]);

    useEffect(() => {
        if (!incomes || incomes.length === 0) {
            return;
        }

        let filteredIncomes = !selectedOffer ? incomes : incomes.filter(income => income.offerId === selectedOffer.id);
        filteredIncomes = !isOnlyActualIncomes ? filteredIncomes : filteredIncomes.filter(income => !income.toDate || income.toDate >= currentDate);
        filteredIncomes = searchValue && searchValue !== "" ? searchByString(filteredIncomes, ["partnerName"], searchValue) : filteredIncomes;
        if (sortableIncomeColumn && sortableIncomeDirection) {
            filteredIncomes = sortableIncomeDirection === ASC
                ? filteredIncomes.sort((a, b) => a[sortableIncomeColumn].localeCompare(b[sortableIncomeColumn]))
                : filteredIncomes.sort((a, b) => b[sortableIncomeColumn].localeCompare(a[sortableIncomeColumn]));
        }
        setFilteredIncomes(filteredIncomes);
        setFilteredIncomesAmount(filteredIncomes.length);

    }, [incomes, isOnlyActualIncomes, offer, partners, sortableIncomeColumn, sortableIncomeDirection, searchValue, currencyRate, selectedOffer]);


    useEffect(() => {
        const offersDropdownData = [
            {id: offer.offerId, name: offer.name, billingType: offer.billingType, billingTypeName: availableBillingTypesData.find(item => item.type === offer.billingType)?.name},
            ...children?.map(child => {return {id: child.id, name: child.offerName, billingType: child.billingType, billingTypeName: availableBillingTypesData.find(item => item.type === child.billingType)?.name}})
        ];
        setOffersDropdownData(offersDropdownData);
        setSelectedOffer(offersDropdownData[0]);
    }, [offer, children]);


    const tableColumns = () => {
        return [
            {
                name: "Дата начала",
                key: "fromDate",
                getBodyCellValue: income => incomeTableColumnFormatters["rusDateString"](income.fromDate),
                headerCellStyle: {minWidth: "110px"}
            },
            {
                name: "Дата окончания",
                key: "toDate",
                getBodyCellValue: income => incomeTableColumnFormatters["rusDateString"](income.toDate),
                headerCellStyle: {minWidth: "110px"}
            },
            {
                name: "Партнер",
                key: "partnerName",
                getBodyCellValue: income => incomeTableColumnFormatters["partnerName"](income.partnerName),
                headerCellStyle: {minWidth: "350px"}
            },
            {
                name: "Ставка фикс",
                key: "income",
                getBodyCellValue: income => {
                    const value = incomeTableColumnFormatters["income"](income.income);
                    return income.incomeType === PERCENT
                        ? <span style={{color: "#b5bcc2"}} title="Вычисленное значение">
                        {value}
                    </span>
                        : value
                },
                headerCellStyle: {minWidth: "90px"},
            },
            {
                name: "Ставка %",
                key: "incomeInPercent",
                getBodyCellValue: income => {
                    const value = incomeTableColumnFormatters["incomeInPercent"](income.incomeInPercent);
                    return isFixedIncomeType(income.incomeType) && value !== ""
                        ? <span style={{color: "#b5bcc2"}} title="Вычисленное значение">
                            {value}
                        </span>
                        : value
                },
                headerCellStyle: {minWidth: "90px"}
            },
            {
                name: "Тип ставки",
                key: "incomeType",
                getBodyCellValue: income => incomeTableColumnFormatters["incomeType"](income.incomeType),
                headerCellStyle: {minWidth: "150px"}
            },
            {
                name: <OfferEditIncomePopover
                    button={<i className="ti ti-plus td-save" title="Добавить"/>}
                    label="Новая ставка"
                    offer={selectedOffer}
                    parentOffer={offer}
                    currencyRate={currencyRate}
                />,
                key: "actions",
                getBodyCellValue: (income) => <>
                    <OfferEditIncomePopover
                        button={<i className="ti ti-pencil td-save" title="Редактировать"/>}
                        label="Редактировать ставку"
                        income={income}
                        offer={selectedOffer}
                        parentOffer={offer}
                        currencyRate={currencyRate}
                    />
                    {income.fromDate > currentDate
                    && <i className="ti ti-trash td-save" onClick={() => deleteIncome(income.offerIncomeId)}
                          title="Удалить"/>}
                    {(NEW_OFFER_IDS.includes(offer.offerId) && (income.clonedIncome || income.newIncome)) &&
                    <i className="ti ti-close td-save"
                       onClick={() => excludeClonedIncome(income)}
                       title="Исключить"/>}
                </>,
                headerCellStyle: {minWidth: "90px", width: "90px"},
                bodyCellStyle: {textAlign: "left"},
                isSortingDisabled: true,
            },
        ];
    };

    const height = useResizeObserver(document.getElementById("scrollable"))?.height - document.getElementById("incomesEditHeader")?.offsetHeight - 30;

    return (
        <div>
            <div id="incomesEditHeader" className="row">
                <div className="col-lg-4 col-sm-12">
                    <label  className="fs10px">Оффер</label>
                    <MaterialUISelect
                        displayedFields={['id', 'name', 'billingTypeName']} dataField="id"
                        dropdownData={offersDropdownData}
                        selectedValue={selectedOffer}
                        onSelectCallbackFunction={setSelectedOffer}
                        isClearable={false}/>
                </div>
                <div className="col" style={{maxWidth: "60px"}}>
                    <OfferEditCloneIncomesPopover button={<button className="circle-btn">
                    <i className="ti ti-files"/></button>} tooltip="Клонировать ставки" label="Клонировать ставки" offer={selectedOffer} parentOffer={offer}/>
                </div>
                {!NEW_OFFER_IDS.includes(offer.offerId)
                && <div className="col col-lg-3 col-sm-10">
                    <div className="form-group edit-offer margin20">
                        <IosCheckbox label="Скрыть устаревшие" labelClassName="float-left"
                                     isChecked={isOnlyActualIncomes}
                                     onClickFunction={() => setIsOnlyActualIncomes(!isOnlyActualIncomes)}/>
                    </div>
                </div>}

            </div>
            <div className="responsive-block" id="fullWidth"
                 style={{
                     overflowX: 'auto',
                     overflowY: 'auto',
                     height: (height + 'px'),
                 }}>
                <SimpleTable
                    tableClassName="table table-bordered nowrap statistic_table sticky-header sticky-action sticky-footer userselecttext"
                    columns={tableColumns()}
                    data={filteredIncomes} bodyRowKey="offerIncomeId" isSortable
                    sortableColumn={sortableIncomeColumn} sortableDirection={sortableIncomeDirection}
                    onSort={setIncomeSorting}/>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        incomesWithChildren: state.offerIncomeReducer.incomesWithChildren,
        currencyRate: state.currencyRateReducer.actualCurrencyRates?.find(currRate => state.managerOffersReducer.offerCardData?.operatorCurrencyId === currRate.currencyId)?.actualRate || 1,
    }
};

export default connect(mapStateToProps, {deleteIncome, loadWithChildrenOfferIncomes})(OfferEditIncomesData);