import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
        color: "#6c757d",
        minWidth: "40px",
        width: "40px",
        height: "40px",
        maxWidth: "58px",
        maxHeight: "58px",
        display: "inline-table"
    },
}));


export default function Spinner() {
    const classes = useStyles();
    return (
        <div className="d-flex justify-content-center">
            <CircularProgress className={classes.progress} />
        </div>
    );

}