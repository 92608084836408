import React from "react";
import {
    CURRENT_MONTH,
    CURRENT_WEEK,
    DATE_DAY,
    DATE_HOUR,
    DATE_MONTH,
    DATE_WEEK,
    LAST_14_DAYS,
    LAST_14_5_DAYS,
    LAST_17_2_DAYS,
    LAST_2_DAYS,
    LAST_30_DAYS,
    LAST_30_5_DAYS,
    LAST_7_DAYS,
    LAST_MONTH,
    LAST_WEEK,
    TODAY,
    YESTERDAY
} from "./constants";

export const fromDate = (period) => {
    let currentDate = getServerCurrentDate();
    let day;
    switch (period) {
        case (TODAY):
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (YESTERDAY):
        case (LAST_2_DAYS):
            currentDate.setDate(currentDate.getDate() - 1);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_7_DAYS):
            currentDate.setDate(currentDate.getDate() - 6);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_14_DAYS):
            currentDate.setDate(currentDate.getDate() - 13);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_14_5_DAYS):
            currentDate.setDate(currentDate.getDate() - 13 - 5);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_17_2_DAYS):
            currentDate.setDate(currentDate.getDate() - 16 - 2);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_30_DAYS):
            currentDate.setDate(currentDate.getDate() - 30);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_30_5_DAYS):
            currentDate.setDate(currentDate.getDate() - 30 - 5);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (CURRENT_WEEK):
            day = currentDate.getDate() - currentDate.getDay();
            currentDate = new Date(currentDate.setDate(day));
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_WEEK):
            day = currentDate.getDate() - currentDate.getDay() - 6;
            currentDate = new Date(currentDate.setDate(day));
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (CURRENT_MONTH):
            return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        case (LAST_MONTH):
            return new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        default:
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
    }
};


export const toDate = (period) => {
    let currentDate = getServerCurrentDate();
    let day;
    switch (period) {
        case (TODAY):
        case (LAST_2_DAYS):
        case (LAST_7_DAYS):
        case (LAST_14_DAYS):
        case (LAST_30_DAYS):
            return currentDate;
        case (LAST_14_5_DAYS):
        case (LAST_30_5_DAYS):
            currentDate.setDate(currentDate.getDate() - 5);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_17_2_DAYS):
            currentDate.setDate(currentDate.getDate() - 2);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (YESTERDAY):
            currentDate.setDate(currentDate.getDate() - 1);
            // currentDate.setHours(23, 59, 59, 0);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (CURRENT_WEEK):
            // currentDate.setHours(23, 59, 59, 0);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_WEEK):
            day = currentDate.getDate() - currentDate.getDay() + 7;
            currentDate = new Date(currentDate.setDate(day));
            // currentDate.setHours(23, 59, 59, 0);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (CURRENT_MONTH):
            // currentDate.setHours(23, 59, 59, 0);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        case (LAST_MONTH):
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            // currentDate.setHours(23, 59, 59, 0);
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        default:
            return currentDate;
    }
};

export function isDateGroupType(groupType) {
        return groupType === DATE_HOUR
            || groupType === DATE_DAY
            || groupType === DATE_WEEK
            || groupType === DATE_MONTH;
}

export function isDateGroupData(groupData) {
    return groupData?.from || groupData?.to;
}

export const isCurrentDay = (date) => {
    const currentServerDate = getServerCurrentDate();
    return date && currentServerDate.getDay() === date.getDay();
};

export const getIsoLocalString = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 10) : "";
};

export const getServerDate = (date) => {
    const timezonesDifference = (process.env.REACT_APP_SERVER_TIMEZONE_OFFSET - date.getTimezoneOffset()) / 60;
    return new Date(date.setHours(date.getHours() - timezonesDifference));
};


export const getServerCurrentDate = () => {
    const currentDate = new Date();
    return getServerDate(currentDate);
};

export const getServerCurrentDay = () => {
    const currentDate = getServerCurrentDate();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
};

export const getRusDateString = (date) => {
        return date
        ? ('0' + date.getDate()).slice(-2)
        + "."
        + ('0' + (date.getMonth() + 1)).slice(-2)
        + "." + date.getFullYear().toString().substr(-4)
        : "";
};

export const getRusDateHourString = (date) => {
    return date
        ? ('0' + date.getDate()).slice(-2)
        + "."
        + ('0' + (date.getMonth() + 1)).slice(-2)
        + "." + date.getFullYear().toString().substr(-4)
        + " "
        + ('0' + date.getHours()).slice(-2)
        + ":"
        + ('0' + date.getMinutes()).slice(-2)
        : "";
};

export const getRusHourDateString = (date) => {
    return date
        ?
        ('0' + date.getHours()).slice(-2)
        + ":"
        + ('0' + date.getMinutes()).slice(-2)
        + " "
        + ('0' + date.getDate()).slice(-2)
        + "."
        + ('0' + (date.getMonth() + 1)).slice(-2)
        + "." + date.getFullYear().toString().substr(-4)
        : "";
};

export const getMaxDate = (datesArr) => {
    if (!datesArr || datesArr.length === 0) {
        return null;
    }
    if (datesArr.length === 1) {
        return datesArr[0];
    }
    return datesArr.reduce((prev, curr) => prev > curr ? prev : curr);
};




