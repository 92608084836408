import React from "react";
import {columnsFormatter} from "../../../../utils/statisticUtils";
import {connect} from "react-redux";

function StatisticTableGroupRow(props) {
    const {columns, rowData, onExpandRow, showDetails, dataIndex, selectedRows} = props;

    const paddingLeft = 10 * rowData.level;

    const expandRow = (e, rowId, dataIndex) => {
        if (e.shiftKey) {
            return;
        }
        onExpandRow(rowId, dataIndex);
    };

    const trClassName = rowData.isOpen
    ? "grouped_row opened" + (selectedRows.includes(rowData.rowId) ?  ' selected_row' : '')
        : "grouped_row"+ (selectedRows.includes(rowData.rowId) ?  ' selected_row' : '');

    return (
        <tr className={trClassName} row_id={rowData.rowId} >
            <td key={"body_cell_" + rowData.rowId + " 0"} className={"statistic_body_group left_position"} style={{paddingLeft: paddingLeft, cursor: "pointer"}} onClick={(e) => expandRow(e, rowData.rowId, dataIndex)}>
                <i className={rowData.isOpen ? "fa fa-chevron-right statistic_table_icon opened" : "fa fa-chevron-right statistic_table_icon"}/>
                {rowData.groupData.groupName}</td>
            {columns.map((column, idx) =>
                <td key={"body_cell_" + rowData.rowId + " " + (idx + 1)} className={"statistic_body_group right_position"}>
                    {columnsFormatter[column.name](rowData[column.name], rowData, props.isLoggedAsPartner, () => showDetails(rowData.rowId, column.name, rowData[column.name]))}
                </td>)}
        </tr>
    );
}

const mapStateToProps = state => ({
    isLoggedAsPartner: state.auth.isPartner || state.auth.isAdminAsPartner
});

export default connect(mapStateToProps, null)(StatisticTableGroupRow);
