import React from "react";
import {URL_TOOLS} from "../../../properties";
import {isAdminAsPartner} from "../../../utils/authUtils";
import {pathParameter} from "../../../utils/urlUtils";
import {useHistory} from 'react-router-dom';


export default function IpRangesButton() {
    const history = useHistory();

    const handleClick = () => {
        const redirectUrl = URL_TOOLS + "?type=iprange" + (isAdminAsPartner() ? "&partnerId=" + pathParameter("partnerId") : "");
        history.push(redirectUrl);
    };

    return (
        <div className="ip-range-btn" onClick={handleClick} title="Диапазон IP">
            <i className="ti ti-world ip-range-icon"/>
        </div>

    )
}