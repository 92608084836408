import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import MaterialUIGroupedMultiSelect from "../../../common/selects/MaterialUIGroupedMultiSelect";

export default function ServiceGroupsFilter({selectedServiceGroups, handleChangeFilterData, inputStyle}) {

    let serviceGroups = useSelector(state => state.serviceGroupsReducer.serviceGroups);

    const [dropdownData, setDropdownData] = useState([]);

    useEffect(() => {
        if (!serviceGroups || serviceGroups.length === 0) {
            return;
        }
        const dropdownData1 = serviceGroups
            .filter(dataItem => (dataItem.subscriptionServices && dataItem.subscriptionServices.length > 0))
            .map(dataItem => {
            return {...dataItem,
                options: dataItem.subscriptionServices.map(service => {return {...service, fullName: service.externalId + ' | ' + service.name}})}
        });
        setDropdownData(dropdownData1);
    }, [serviceGroups]);


    const onSelect = (value) => {
        handleChangeFilterData("serviceGroups", value, true);
    };

    return (
        <MaterialUIGroupedMultiSelect
            styles={inputStyle}
            dropdownData={dropdownData}
            groupDisplayedField='name'
            groupDataField='id'
            values={selectedServiceGroups}
            dataField='externalId'
            displayedField='fullName'
            label='Группы сервисов'
            placeholder={'-'}
            onSelect={onSelect}
            isSearchable
        />
    );
}