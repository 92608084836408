import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";

export default function OffersOperatorFilter({selectedOperators, selectedCountries, onSelectOperator, offers, inputStyle}) {
    const allOperators = useSelector(state => state.dictionaries.operators);
    const activeOperators = useSelector(state => state.dictionaries.activeOperators);

    const [dropdownValues, setDropdownValues] = useState([]);

    useEffect(() => {
        const getFilteredOperators = () => {
            let resultOperators = [];
            if (activeOperators && offers) {
                let availableOperatorIds = selectedCountries.length > 0 && selectedCountries.flatMap(country => country.operatorIds);
                activeOperators.forEach(function (operator) {
                    const found = offers.find(function (offer) {
                        return offer.operatorId === operator.operatorId && (!availableOperatorIds || availableOperatorIds.includes(offer.operatorId));
                    });
                    if (found) {
                        resultOperators.push(operator);
                    }
                })
            }
            return resultOperators;
        };
        setDropdownValues(getFilteredOperators());
    }, [selectedOperators, selectedCountries, offers, allOperators]);


    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedOperators} dataField='operatorId'
                               displayedField='operatorName' label='Операторы' placeholder='-'
                               onSelect={onSelectOperator}/>
    )
}


