import React, {useEffect} from "react";
import SmallNavTab from "./SmallNavTab";
import {connect, useSelector} from "react-redux";
import {loadPartnerCaps} from "../../../actions/capActions";

function SmallNavTabs({tabs, selectedTabName, onSelectTab, additionalElements, tabsData, loadPartnerCaps}) {

    const auth = useSelector(state => state.auth);
    const {isAdminAsPartner, isPartner} = auth;
    const isPartnerPage = isAdminAsPartner || isPartner;
    useEffect(() => {
        isPartnerPage && loadPartnerCaps();
    }, []);


    return (
        <>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {tabs && Object.keys(tabs).map(tabName =>
                    <SmallNavTab key={tabName} label={tabs[tabName]} selected={tabName === selectedTabName}
                                 onSelect={() => onSelectTab(tabName)} id={tabsData && tabsData[tabName].id}/>)}
                {isPartnerPage}
                {additionalElements}
            </div>
        </>
    )
}

export default connect(null, {loadPartnerCaps})(SmallNavTabs);
