import React from "react";
import Spinner from "../../common/Spinner";
import NoContentMessage from "../../common/NoContentMessage";
import {getMoneyString, moneyCellFormat} from "../../../utils/formatUtils";
import {getServerCurrentDate} from "../../../utils/dateUtils";
import ExportToXlsx from "../../common/xlsx/ExportToXlsx";
import xlsxIcon from "../../../resources/images/icons/xls.svg";
import SimpleSortableTable from "../../common/tables/SimpleSortableTable";

export default function PartnerReferralsTable({isLoading, tableData, sortableColumn, sortableDirection, sort}) {

    const sumValue = (data, fieldName) => {
        if (!data) {
            return "";
        }
        const result = data.reduce((a, b) => a + b[fieldName], 0);
        return getMoneyString(result);
    };


    const columns = [
        {
            name: "encryptedPartnerId",
            label: "Реферал",
            footerValue: () => "Итого:",
            headerStyle: {textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "encryptedPartnerId",
        },
        {
            name: "partnerRegistrationDate",
            label: "Дата регистрации",
            footerValue: () => "-",
            headerStyle: {minWidth: "120px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Дата регистрации",
        },
        {
            name: "income",
            label: "Доход",
            footerValue: (data) => sumValue(data, "income"),
            headerStyle: {textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "right"}
            },
            formatBodyCell: (value, data) => getMoneyString(data.income),
            footerStyle: {minWidth: "100px", textAlign: "right"},
            xlsxLabel: "Доход",
            xlsxNumFormat: (value) => moneyCellFormat(value),
        }
    ];

    const xlsxColumns = () => {
        let xlsxColumns = columns.map(column => {
            return {title: column.xlsxLabel, width: {wch: (column.xlsxLabel.length + 5)}, height: {wpx: 14}}
        });
        xlsxColumns[0].width = {wch: 30};
        return xlsxColumns;
    };

    const xlsxData = () => {
        return tableData && tableData.map((row) => {
            const xlsxRow = [];
            columns.forEach((column, colIdx) => {
                const horizontalAlignment = (colIdx === 0) ? "center" : "right";
                xlsxRow.push({
                    value: column.formatXlsxBodyCell ? column.formatXlsxBodyCell(row[column.name], row) : row[column.name],
                    style: {
                        font: {sz: "11"},
                        alignment: {horizontal: horizontalAlignment},
                        numFmt: column.xlsxNumFormat && column.xlsxNumFormat(row[column.name])
                    }
                });
            });
            return xlsxRow;
        })
    };

    const xlsxDataSet = () => {
        return [{columns: xlsxColumns(), data: xlsxData()}];
    };

    const filename = () => {
        const date = getServerCurrentDate();
        return "am-ref-stat-" + date.getFullYear() + date.getMonth() + date.getDate() + "-" + date.getHours() + date.getMinutes();
    };

    const table =
        <>
            <ExportToXlsx name={filename()} dataSet={xlsxDataSet()} listName="Реферальная программа" button={
                <img className="exportXls float-right" style={{cursor: "pointer"}} src={xlsxIcon} alt=""/>
            }/>
            <br/>
            <div className="responsive-block statistic_table_wrapper" id="fullWidth">
                <SimpleSortableTable columns={columns} renderedData={tableData} allData={tableData}
                                     sortableColumn={sortableColumn}
                                     sortableDirection={sortableDirection} onSort={sort}/>
            </div>
            <br/>
        </>;


    return (
        <div className="card">
            <div className="row"/>
            <div className="card-body"/>
            <div className="col-12">
                <hr/>
                <div>
                    {isLoading && <Spinner/>}
                    {!isLoading && tableData && tableData.length > 0 && table}
                    {!isLoading && (!tableData || tableData.length === 0) && <NoContentMessage/>}
                </div>
            </div>
        </div>
    )
}