import React, {useEffect, useState} from "react";
import {Popover} from "@material-ui/core";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {availableBillingTypesData, NEW_OFFER_IDS, newOffer} from "../../../../utils/offerUtils";
import {connect} from "react-redux";
import {addChild, loadChildren, loadOffer} from "../../../../actions/managerOfferActions";
import CircleButtonsGroup from "../../../common/buttons/CircleButtonsGroup";

function OfferEditChildrenPopover({button, label = "Привязать оффер", tooltip, onOpenCallback, allOffers, parentOffer, addChild, addChildToClone, loadChildren}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [offersDropdownData, setOffersDropdownData] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [availableBillingTypes, setAvailableBillingTypes] = useState(null);
    const [selectedBillingType, setSelectedBillingType] = useState(null);

    useEffect(() => {
        if (!open || !allOffers) {
            return;
        }

        const availableBillingTypesForAdding = availableBillingTypesData.filter(billingType => parentOffer.billingType !== billingType.type);
        setAvailableBillingTypes(availableBillingTypesForAdding);

        if (availableBillingTypesForAdding?.length > 0) {
            setSelectedBillingType(availableBillingTypesForAdding[0]);
        }


    }, [open]);


    useEffect(() => {
        if (!selectedBillingType) {
            return;
        }
        const offersDropdownData = allOffers.filter(offer => offer.billingType === selectedBillingType.type && !offer.parentId && offer.externalServiceId === parentOffer.subscriptionService.externalId);
        setOffersDropdownData([newOffer(selectedBillingType.type), ...offersDropdownData]);
    }, [selectedBillingType, allOffers]);


    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        onOpenCallback && onOpenCallback();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddChild = () => {
        if (NEW_OFFER_IDS.includes(parentOffer.offerId)) {
            const child = {
                ...selectedOffer,
                billingType: selectedBillingType?.type,
            };
            addChildToClone(child);
        } else {
            const body = {
                offerId: parentOffer?.offerId,
                childId: !selectedOffer?.id || NEW_OFFER_IDS.includes(selectedOffer?.id) ? null : selectedOffer.id,
                childBillingType: selectedBillingType?.type,
            };
            addChild(body, () => loadChildren(parentOffer.offerId));
        }
    };
    const muiSelectStyle = {root: {minWidth: "min(90vw, 370px)"}};

    return (
        <span>
            <div aria-describedby={id} onClick={handleClick} title={tooltip}>
                {button}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <div style={{padding: "10px", textAlign: "center"}}>{label}</div>
                <div style={{padding: "20px"}}>
                   <MaterialUISelect
                       dropdownData={availableBillingTypes}
                       dataField="type"
                       displayedField="fullName"
                       selectedValue={selectedBillingType}
                       onSelectCallbackFunction={setSelectedBillingType}
                       isClearable={false}
                       placeholder="-"
                       label="Тип оффера"
                       styles={muiSelectStyle}
                   />
                    <MaterialUISelect
                        dropdownData={offersDropdownData}
                        dataField="id"
                        displayedFields={['id', 'offerName']}
                        selectedValue={selectedOffer}
                        onSelectCallbackFunction={setSelectedOffer}
                        isClearable={false}
                        isSearchable
                        placeholder="-"
                        label="Оффер"
                        styles={muiSelectStyle}
                    />
                    <br/>
                    <CircleButtonsGroup buttons={
                        [
                            {
                                isSelected: false,
                                title: "Сохранить",
                                iconClassName: "ti ti-check stat-icon",
                                confirmMessage: "Привязать дочерний оффер?",
                                onClick: handleAddChild
                            },
                            {
                                isSelected: false,
                                title: "Отмена",
                                iconClassName: "ti ti-close stat-icon",
                                onClick: handleClose
                            },
                        ]
                    }/>
                </div>
            </Popover>
        </span>
    )
};

const mapStateToProps = (state) => {
    return {
        allOffers: state.managerOffersReducer.allOffers,
    }
};


export default connect(mapStateToProps, {
    loadOffer,
    addChild,
    loadChildren,

})(OfferEditChildrenPopover);