import React from "react";
import PropTypes from "prop-types";
import {AZ, BEELINE, BY, KZ, MEGAFON, MTS, RU, TELE_2, UA, UNKNOWN, UZ, TJ} from "../../../utils/constants";
import {getOperatorImg} from "../../../utils/operatorsUtil";



OperatorImage.propTypes = {
    type: PropTypes.oneOf([BEELINE, MTS, MEGAFON, TELE_2, AZ, BY, KZ, RU, UA, UZ, UNKNOWN]),
    className: PropTypes.string,
    width: PropTypes.string,
};

export default function OperatorImage({type, className, width}) {
        return <img src={getOperatorImg(type)} alt="" className={className} width={width}/>
}