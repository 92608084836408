import {
    DASHBOARD_CHART_DATA_LOADING,
    DASHBOARD_CHART_DATA_LOADED,
    DASHBOARD_ACTIVE_OFFERS_LOADED,
    DASHBOARD_ACTIVE_OFFERS_LOADING
} from "../../actions/actionTypes"

const initialState = {
    chartData: null,
    loading: false,
    dashboardOffers: [],
    offersLoading: false,
};

export default function getDashboardChartData(state = initialState, action = {}) {
    switch (action.type) {
        case DASHBOARD_CHART_DATA_LOADED:
            return {
                ...state,
                chartData: action.payload,
                loading: false};
        case DASHBOARD_CHART_DATA_LOADING:
            return {
                ...state,
                loading: true
            };
        case DASHBOARD_ACTIVE_OFFERS_LOADING:
            return {
                ...state,
                offersLoading: true
            };
        case DASHBOARD_ACTIVE_OFFERS_LOADED:
            return {
                ...state,
                dashboardOffers: action.payload,
                offersLoading: false,
                mainSwitcherChecked: true
            };
        default:
            return state;

    }
}