import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import BuyoutRulesIsOnlyNonStandardFilter from "./BuyoutRulesIsOnlyNonStandardFilter";

export default function BuyoutRulesFilters(props) {
    const {filters, onSelectFilterValue, toggleCheckboxFilter, subscriptionServicesDropdownData, inputStyle} = props;
    const partners = useSelector(state => state.managerPartnersReducer.partnersList);
    const allOffers = useSelector(state => state.managerOffersReducer.allOffers);

    const [filteredBuyoutOffers, setFilteredBuyoutOffers] = useState([]);


    useEffect(() => {
        if (subscriptionServicesDropdownData.length === 0 || filters.selectedSubscriptionServices.length === 0) {
            setFilteredBuyoutOffers(allOffers);
        } else {
            const serviceExternalIds = filters.selectedSubscriptionServices.map(service => service.externalId);
            setFilteredBuyoutOffers(allOffers.filter(offer => serviceExternalIds.includes(offer.externalServiceId)));
        }
    }, [subscriptionServicesDropdownData, filters.selectedSubscriptionServices]);

    return (
        <>
            <MaterialUIMultiSelect dropdownData={partners} values={filters.selectedPartners} dataField='id'
                                   displayedField='partnerName' label='Партнёры' placeholder='-'
                                   onSelect={(value) => onSelectFilterValue(value, "selectedPartners")} isSearchable styles={inputStyle}/>
            <MaterialUIMultiSelect dropdownData={subscriptionServicesDropdownData} values={filters.selectedSubscriptionServices} dataField='id'
                                   displayedFields={['externalId', 'name']} label='Сервисы' placeholder='-'
                                   onSelect={(value) => onSelectFilterValue(value, "selectedSubscriptionServices")} isSearchable styles={inputStyle}/>
            <MaterialUIMultiSelect dropdownData={filteredBuyoutOffers} values={filters.selectedOffers} dataField='id'
                                   displayedFields={['id', 'offerName']} label='Офферы' placeholder='-'
                                   onSelect={(value) => onSelectFilterValue(value, "selectedOffers")} isSearchable styles={inputStyle}/>
            <BuyoutRulesIsOnlyNonStandardFilter isOnlyNonDefaultRules={filters.isOnlyNonDefaultRules} toggleIsOnlyNonDefaultRules={() => toggleCheckboxFilter("isOnlyNonDefaultRules")}/>
        </>
    )

}