import React, {useEffect, useState} from "react";
import {getMoneyString, moneyCellFormat, sumFieldsAndReturnMoneyString} from "../../../../../utils/formatUtils";
import {
    PAID,
} from "../../../../../utils/constants";
import ActionPopover from "../../../../common/popovers/ActionPopover";
import {getServerCurrentDate} from "../../../../../utils/dateUtils";
import TableCheckbox from "../../../../common/checkboxes/TableCheckbox";
import SimpleSortableTable from "../../../../common/tables/SimpleSortableTable";
import NoContentMessage from "../../../../common/NoContentMessage";
import ManagerPaymentPopover from "../popover/ManagerPaymentPopover";
import {statusesForApprove} from "../../../../../utils/paymentUtils";
import {isMobileHorizontalScreen, isMobileVerticalScreen} from "../../../../../utils/displayUtils";
import useResizeObserver from "../../../../../hooks/useResizeObserver";

const NUMBER_OF_ROWS_TO_RENDER = 20;

export default function ManagerPaymentsTable({
                                                 tableData = [], tableDataChanges, sortableColumn, sortableDirection, sort,
                                                 checkedRowIds = [], toggleCheckRow, toggleCheckAllRows, handleEditCell,
                                                 cancelPayment, approvePayment, selectedStatuses,
                                                 sumFooterWithChanges, loadTableData, getXlsxReportData, getApproveButtonTooltipValue
                                             }) {


    const getCellColor = (tableDataChangesItem) => {
        if (!tableDataChangesItem) {
            return;
        }
        return tableDataChangesItem.invalid ? "#dc354526" : "rgb(0 128 0 / 8%)";
    };

    const columns = [
        {
            name: "checkBox",
            isSortingDisabled: true,
            label: selectedStatuses && selectedStatuses.length === 1 && selectedStatuses[0] !== PAID
                ? <TableCheckbox checked={checkedRowIds.length > 0 && checkedRowIds.length === tableData.length}
                                 id="chbx_all" onChange={toggleCheckAllRows}/>
                : <></>,
            footerValue: () => "Итого:",
            headerStyle: {minWidth: "40px", width: "40px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            formatBodyCell: (value, data) => selectedStatuses && selectedStatuses.length === 1 && selectedStatuses[0] !== PAID
                ? <TableCheckbox id={"chbx_" + data.id} checked={checkedRowIds.includes(data.id)}
                                 onChange={() => toggleCheckRow(data.id)}/>
                : <></>,
            footerStyle: {textAlign: "center"},
            xlsxLabel: "",
            formatXlsxBodyCell: () => "",
        },
        {
            name: "partnerName",
            label: "Партнёр",
            footerValue: () => "-",
            headerStyle: {minWidth: "200px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "left"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Партнёр",
            formatXlsxBodyCell: value => value ? value : "",
        },
        {
            name: "paymentTypeValue",
            label: "Тип выплаты",
            footerValue: () => "-",
            headerStyle: {textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center", fontWeight: 600}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Тип выплаты",
            formatXlsxBodyCell: value => value ? value : "",
        },
        {
            name: "createDate",
            label: "Дата начисления",
            footerValue: () => "-",
            headerStyle: {minWidth: "130px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Дата начисления",
            formatXlsxBodyCell: value => value ? value : ""
        },
        {
            name: "payDate",
            label: "Дата оплаты",
            footerValue: () => "-",
            headerStyle: {minWidth: "130px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Дата оплаты",
            formatXlsxBodyCell: value => value ? value : ""
        },
        {
            name: "period",
            label: "Период",
            footerValue: () => "-",
            headerStyle: {minWidth: "200px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Период",
            formatXlsxBodyCell: value => value ? value : "",
        },
        {
            name: "paymentStatus",
            label: "Статус",
            footerValue: () => "-",
            headerStyle: {minWidth: "100px", textAlign: "center"},
            formatBodyCell: (data) => data.value,
            bodyStyle: (data) => {
                return {
                    textAlign: "center", fontWeight: 600,
                    color: data.paymentStatus && data.paymentStatus.cellColor
                }
            },
            footerStyle: {textAlign: "center"},
            formatXlsxBodyCell: data => data ? data.value : "",
            xlsxLabel: "Статус",
        },
        {
            name: "periodIncomeApproved",
            label: "Доход за ПП",
            footerValue: () => sumFooterWithChanges("periodIncomeApproved"),
            headerStyle: {minWidth: "120px", textAlign: "center"},
            bodyStyle: (data) => {
                return {
                    textAlign: "right",
                    backgroundColor: tableDataChanges[data.id] && tableDataChanges[data.id]["periodIncomeApproved"] && getCellColor(tableDataChanges[data.id]["periodIncomeApproved"])
                }
            },
            formatBodyCell: (value, data) => getMoneyString(value),
            footerStyle: {textAlign: "right"},
            xlsxLabel: "Доход за ПП",
            xlsxNumFormat: (value) => moneyCellFormat(value),
            isEditable: (data) => data.isLastPayment && data.status !== PAID,
        },
        {
            name: "incomePaidApproved",
            label: "К выплате",
            footerValue: (data) => sumFooterWithChanges("incomePaidApproved"),
            headerStyle: {minWidth: "100px", textAlign: "center"},
            bodyStyle: (data) => {
                return {
                    textAlign: "right",
                    backgroundColor: tableDataChanges[data.id] && tableDataChanges[data.id]["incomePaidApproved"] && getCellColor(tableDataChanges[data.id]["incomePaidApproved"])
                }
            },
            formatBodyCell: (value) => getMoneyString(value),
            footerStyle: {textAlign: "right"},
            xlsxLabel: "К выплате",
            xlsxNumFormat: (value) => moneyCellFormat(value),
            isEditable: (data) => data.isLastPayment && data.status !== PAID,
        },
        {
            name: "periodIncomeRemain",
            label: "Задолженность после оплаты периода",
            footerValue: (data) => sumFieldsAndReturnMoneyString(data, "periodIncomeRemain"),
            headerStyle: {minWidth: "160px", width: "160px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "right"}
            },
            formatBodyCell: (value) => getMoneyString(value),
            footerStyle: {textAlign: "right"},
            xlsxLabel: "Задолженность после оплаты периода",
            xlsxNumFormat: (value) => moneyCellFormat(value),
        },
        {
            name: "incomeToDate",
            label: "Задолженность накопительная",
            footerValue: (data) => sumFieldsAndReturnMoneyString(data, "incomeToDate"),
            headerStyle: {width: "140px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "right"}
            },
            formatBodyCell: (value) => getMoneyString(value),
            footerStyle: {textAlign: "right"},
            xlsxLabel: "Задолженность накопительная",
            xlsxNumFormat: (value) => moneyCellFormat(value),
        },
        {
            name: "paymentDataValue",
            label: "Реквизиты",
            footerValue: () => "-",
            formatBodyCell: (value) => value,
            headerStyle: {minWidth: "130px", width: "130px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "center"}
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Реквизиты",
            formatXlsxBodyCell: value => value ? value : ""
        },
        {
            name: "comment",
            label: "Комментарий",
            footerValue: () => "-",
            headerStyle: {width: "200px", textAlign: "center"},
            bodyStyle: (data) => {
                return {
                    textAlign: "center",
                    backgroundColor: tableDataChanges[data.id] && tableDataChanges[data.id]["comment"] ? "rgb(0 128 0 / 8%)" : null
                }
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "Комментарий",
            isEditable: (data) => data.status !== PAID,
            formatXlsxBodyCell: value => value ? value : ""
        },
        {
            name: "actions",
            isSortingDisabled: true,
            label: "",
            footerValue: () => "",
            headerStyle: {width: "100px", textAlign: "center"},
            bodyStyle: () => {
                return {textAlign: "left"}
            },
            formatBodyCell: (value, data) => {
                return (

                    <div style={{display: "inline-flex"}}>
                        {(data.isLastPayment && statusesForApprove.includes(data.status)) &&
                        <>
                            < ActionPopover
                                button={
                                    <i className="ti ti-check" style={{cursor: "pointer", fontSize: "14px"}}/>
                                }
                                tooltip={getApproveButtonTooltipValue(data.status)}
                                popoverLocation='right'
                                label={getApproveButtonTooltipValue(data.status) + '?'}
                                handleSubmit={() => approvePayment(data)}
                            />
                            <ActionPopover
                                button={
                                    <i className="ti ti-close" style={{cursor: "pointer", fontSize: "14px"}}/>
                                }
                                tooltip={'Отменить выплату?'}
                                popoverLocation='right'
                                label={'Отменить выплату?'}
                                handleSubmit={() => cancelPayment(data)}
                            />
                        </>
                        }
                        <ManagerPaymentPopover
                            button={<i className="ti ti-pencil" style={{cursor: "pointer", fontSize: "14px"}}/>}
                            payment={data}
                            loadTableData={loadTableData}/>
                    </div>
                )
            },
            footerStyle: {textAlign: "center"},
            xlsxLabel: "",
            formatXlsxBodyCell: () => "",
        },
    ];

    const [lastIndex, setLastIndex] = useState(0);
    const [renderedData, setRenderedData] = useState([]);

    useEffect(() => {
        if (!tableData) {
            return;
        }
        const endIdx = (lastIndex + NUMBER_OF_ROWS_TO_RENDER) < tableData.length ? (lastIndex + NUMBER_OF_ROWS_TO_RENDER) : tableData.length;
        changeRenderedData(endIdx, tableData);
        const dataset = xlsxDataSet();
        const fileName = filename();
        getXlsxReportData({filename: fileName, dataset: dataset});
    }, [tableData]);


    const onTableScroll = (e) => {
        const srcElement = e.target;
        if (srcElement.clientHeight + srcElement.scrollTop < (srcElement.scrollHeight - 205)) {
            return;
        }
        const endIdx = (tableData.length - renderedData.length) > NUMBER_OF_ROWS_TO_RENDER ? (renderedData.length + NUMBER_OF_ROWS_TO_RENDER) : tableData.length;
        changeRenderedData(endIdx, tableData);
    };

    const changeRenderedData = (lastIndex, bodyRowsData) => {
        setLastIndex(lastIndex);
        const renderedData = bodyRowsData.slice(0, lastIndex);
        setRenderedData(renderedData);
    };

    const xlsxColumns = () => {
        let xlsxColumns = columns.map(column => {
            return {title: column.xlsxLabel, width: {wch: (column.xlsxLabel.length + 5)}, height: {wpx: 14}}
        });
        xlsxColumns[0].width = {wch: 1};
        xlsxColumns[1].width = {wch: 40};
        xlsxColumns[5].width = {wch: 22};
        xlsxColumns[7].width = {wch: 15};
        xlsxColumns[8].width = {wch: 15};
        xlsxColumns[11].width = {wch: 40};
        return xlsxColumns;
    };

    const xlsxData = () => {
        return tableData && tableData.map((row) => {
            const xlsxRow = [];
            columns.forEach((column, colIdx) => {
                const horizontalAlignment = (colIdx === 0) ? "center" : "right";
                xlsxRow.push({
                    value: column.formatXlsxBodyCell ? column.formatXlsxBodyCell(row[column.name], row) : row[column.name],
                    style: {
                        font: {sz: "11"},
                        alignment: {horizontal: horizontalAlignment},
                        numFmt: column.xlsxNumFormat && column.xlsxNumFormat(row[column.name])
                    }
                });
            });
            return xlsxRow;
        })
    };

    const xlsxDataSet = () => {
        return [{columns: xlsxColumns(), data: xlsxData()}];
    };

    const filename = () => {
        const date = getServerCurrentDate();
        return "am-payments-" + (selectedStatuses ? (selectedStatuses.map(status => status.toLowerCase()) + "-") : "")
            + date.getFullYear() + date.getMonth() + date.getDate() + "-" + date.getHours() + date.getMinutes();
    };

    const scrollableObserver = useResizeObserver(document.getElementById("scrollable"));

    const getTableHeight = () => {
        let statisticTableHeight = scrollableObserver?.height;
        if (!isMobileVerticalScreen() && !isMobileHorizontalScreen()) {
            statisticTableHeight -= 30;
        }
        return statisticTableHeight + "px"
    };


    return (
        renderedData && renderedData.length > 0
            ?
            <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={onTableScroll} style={{height: getTableHeight()}}>
                <SimpleSortableTable columns={columns} renderedData={renderedData} allData={tableData}
                                     sortableColumn={sortableColumn} bodyRowKey="id"
                                     sortableDirection={sortableDirection} onSort={sort}
                                     tableClassName="table table-sm nowrap statistic_table sticky-header sticky-footer userselecttext"
                                     handleEditCell={handleEditCell}/>
            </div>
            :
            <NoContentMessage/>
    )
}