import React, {useEffect, useState} from "react";
import StatisticTableSimpleRow from "./StatisticTableSimpleRow";
import StatisticTableGroupRow from "./StatisticTableGroupRow";
import {getFromLocalStorage} from "../../../../utils/localStorageUtils";
import {LOCAL_STORAGE_THEME} from "../../../../utils/constants";
import {addIfNotExist, addOrRemoveAndReturnNewArray, removeIfExist} from "../../../../utils/arrayUtils";

export default function StatisticTableBody(props) {
    const {columns, data, onExpandRow, showDetails, selectedRows, setSelectedRows, rootRows} = props;
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [keyPressed, setKeyPressed] = useState(false);
    const [lastSelectedRowId, setLastSelectedRowId] = useState(null);
    useEffect(() => {
        const savedTheme = getFromLocalStorage(LOCAL_STORAGE_THEME);
        setSelectedTheme(savedTheme ? savedTheme.value : "lightTheme")
    }, [data]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 27) {
                setSelectedRows([]);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleMouseDown = (e) => {
        if (!e.shiftKey) {
            setSelectedRows([]);
            return;
        }
        setKeyPressed(true);
        const trEl = e.target && e.target.parentNode;
        const rowId = trEl && trEl.getAttribute("row_id") && parseInt(trEl.getAttribute("row_id"));
        if (!rootRows.includes(rowId)) {
            return;
        } else {
            (rowId || rowId === 0) && setSelectedRows(addOrRemoveAndReturnNewArray(selectedRows, rowId));
            setLastSelectedRowId(rowId);
        }
    };

    const handleMouseUp = (e) => {

        setKeyPressed(false);
        setLastSelectedRowId(null);
    };

    const handleMouseMove = (e) => {
        if (!keyPressed || !e.shiftKey) {
            return;
        }
        const trEl = e.target && e.target.parentNode;
        const rowId = trEl && trEl.getAttribute("row_id") && parseInt(trEl.getAttribute("row_id"));
        if ((!rowId && rowId !== 0) || lastSelectedRowId === rowId || !rootRows.includes(rowId)) {
            return;
        }
        if (lastSelectedRowId !== rowId) {
            if (selectedRows.includes(rowId)) {
                setSelectedRows(removeIfExist(selectedRows, rowId));
            } else {
                setSelectedRows(addIfNotExist(selectedRows, rowId));
            }
            setLastSelectedRowId(rowId);
        }

    };

    return (
        <tbody className="statistic_body" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
        {
            data.map((rowData, index) =>
                rowData.isGroupRow
                    ? <StatisticTableGroupRow key={"body_row_" + rowData.rowId} columns={columns} rowData={rowData}
                                              onExpandRow={onExpandRow} showDetails={showDetails} dataIndex={index} theme={selectedTheme}
                                               selectedRows={selectedRows}/>
                    : <StatisticTableSimpleRow key={"body_row_" + rowData.rowId} columns={columns} rowData={rowData} showDetails={showDetails}
                                               selectedRows={selectedRows}/>)
        }
        </tbody>
    );
}

