import React from "react";

import filterImg from "../../../resources/images/filter.png";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON} from "../../../actions/actionTypes";
import {setToLocalStorage} from "../../../utils/localStorageUtils";
import {LOCAL_STORAGE_IS_FILTERS_OPEN} from "../../../utils/constants";
import classNames from "classnames";

const STORAGE_PERIOD_IN_HOURS = 365 * 24;

export default function ToggleFiltersButton() {

    const dispatch = useDispatch();
    const isFilterOpenByMouseMove = useSelector(state => state.navigationReducer.isFilterBlockOpenByMouse);
    const isFilterOpenByBtn = useSelector(state => state.navigationReducer.isFilterBlockOpenByBtn);
    const btnClass = classNames("filters-btn", { active: isFilterOpenByBtn });
    const isOpen = isFilterOpenByMouseMove || isFilterOpenByBtn;


   const toggleFiltersBtn = () => {
       dispatch({type: TOGGLE_OPEN_FILTER_BLOCK_BY_BUTTON, payload: !isOpen});
       const localStorageData = {value: !isOpen};
       setToLocalStorage(LOCAL_STORAGE_IS_FILTERS_OPEN, localStorageData, STORAGE_PERIOD_IN_HOURS);
   };

    return (
        <div className={btnClass} onClick={toggleFiltersBtn} title="Фильтры">
            <img src={filterImg} alt="" id="fbtn" />
        </div>
    );
}
