import React, {useEffect, useState} from "react";
import {IconButton, Popover} from "@material-ui/core";
import Done from '@material-ui/icons/DoneOutlined';
import Cancel from '@material-ui/icons/ClearOutlined';
import ManagerPaymentPopoverPartnerInput from "./ManagerPaymentPopoverPartnerInput";
import {connect} from "react-redux";
import {loadManagerPartnerBalanceData} from "../../../../../actions/partnerBalanceActions";
import {loadAdNetworkBalance, loadAllActiveNetworks} from "../../../../../actions/advertisingNetworkActions";
import {loadActualCurrencyRates} from "../../../../../actions/currencyRateActions";
import {loadUserPresets, savePreset} from "../../../../../actions/presetActions";
import ManagerPaymentPopoverPaymentTypeSelect from "./ManagerPaymentPopoverPaymentTypeSelect";
import { getAllPaymentTransferTypes, isTypesWithSamePaymentDetails } from "../../../../../utils/paymentUtils";
import {
    AD_NETWORK_CALCULATION,
    AD_NETWORK_ORDER, BALANCE_CORRECTION, CALCULATED,
    CALCULATION, NONE,
    ORDER, PARTNER_AD_NETWORK_DATA,
    PARTNER_PAYMENT_DETAILS, PENDING,
    REGULAR
} from "../../../../../utils/constants";
import TextField from "@material-ui/core/TextField/TextField";
import {getMoneyString, toNumber} from "../../../../../utils/formatUtils";
import {isNumberStringWithTwoDecimalPlaces} from "../../../../../utils/validationUtils";
import ManagerPaymentPopoverIncome from "./ManagerPaymentPopoverBalance";
import ManagerPaymentPopoverPaymentDetails from "./ManagerPaymentPopoverPaymentDetails";
import {createPayment, updatePaymentByManager} from "../../../../../actions/paymentsActions";


const allPaymentTransferTypes = getAllPaymentTransferTypes();

const initialPayment = {
    id: null,
    type: allPaymentTransferTypes[0].value,
    status: CALCULATED,
    paymentData: {},
    periodIncomeApproved: 0,
    incomePaidApproved: 0,
};

function ManagerPaymentPopover({
                                   button, className, payment, partners, adNetworksDropdownData, adNetworkBalanceData, loadManagerPartnerBalanceData,
                                   loadUserPresets, loadAllActiveNetworks, loadActualCurrencyRates, loadAdNetworkBalance,
                                   currencyRates, partnerBalanceData, managerPartnerBalanceDataLoading, partnerPaymentDetailsPresets,
                                   partnerAdNetworkPresets, createPayment, updatePaymentByManager, loadTableData
                               }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [editablePayment, setEditablePayment] = useState({...initialPayment});
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [selectedType, setSelectedType] = useState(allPaymentTransferTypes[0]);
    const [usdCurrencyRate, setUsdCurrencyRate] = useState(null);
    const [partnerBalance, setPartnerBalance] = useState(null);

    useEffect(() => {
        if (!open) {
            return;
        }

        if (!payment) {
            setEditablePayment({...initialPayment});
            setSelectedPartner(null);
            setSelectedType(allPaymentTransferTypes[0]);
            setPartnerBalance(null);
        } else {
            const partnerId = payment.partnerId;
            const partner = partners.find(partner => partner.id === partnerId);
            setSelectedPartner(partner);
            setEditablePayment({...payment, partnerName: partner.partnerName, partnerId: partner.id});
            const type = allPaymentTransferTypes.find(transferType => transferType.value === payment.type);
            setSelectedType(type);
        }
    }, [open, payment]);

    useEffect(() => {
        if (!selectedPartner || !editablePayment.type) {
            return;
        }
        loadManagerPartnerBalanceData && loadManagerPartnerBalanceData(editablePayment.partnerId);
        switch (editablePayment.type) {
            case CALCULATION:
            case ORDER:
            case REGULAR:
                loadPaymentDetailsPresets();
                break;
            case AD_NETWORK_CALCULATION:
            case AD_NETWORK_ORDER:
                (!adNetworksDropdownData || adNetworksDropdownData.length === 0) && loadAllActiveNetworks();
                loadAdNetworksPresets();
                break;
            case BALANCE_CORRECTION:
                setEditablePayment({...editablePayment, paymentData: {paymentType: NONE}});
            default:
                break;
        }
    }, [selectedPartner, editablePayment.type]);

    useEffect(() => {
        if (!currencyRates) {
            return;
        }
        const usdCurrencyRate = currencyRates.find(rate => rate.charCode === 'USD');
        usdCurrencyRate && setUsdCurrencyRate(usdCurrencyRate.actualRate);
    }, [currencyRates]);

    useEffect(() => {
        if (managerPartnerBalanceDataLoading || !partnerBalanceData || (selectedPartner && selectedPartner.id !== partnerBalanceData.partnerId)) {
            setPartnerBalance("0,00");
        } else {
            setPartnerBalance(getMoneyString(partnerBalanceData.balance));
        }
    }, [managerPartnerBalanceDataLoading, partnerBalanceData, selectedPartner]);

    const loadPaymentDetailsPresets = () => {
        loadUserPresets(PARTNER_PAYMENT_DETAILS, "PARTNER", editablePayment.partnerId);
    };

    const loadAdNetworksPresets = () => {
        loadUserPresets(PARTNER_AD_NETWORK_DATA, "PARTNER", editablePayment.partnerId);
    };

    const loadAdNetworkBalanceData = (id) => {
        loadAdNetworkBalance(id);
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        (!currencyRates || currencyRates.length === 0) && loadActualCurrencyRates();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectType = (type) => {
        setSelectedType(type);
        const newType = type.value;
        const oldType = editablePayment.type;
        if (isTypesWithSamePaymentDetails(newType, oldType)) {
            setEditablePayment({...editablePayment, type: type.value});
        } else {
            setEditablePayment({...editablePayment, type: type.value, paymentData: {}, paymentDataValue: ""})
        }
    };

    const handleSelectPartner = (partner) => {
        setSelectedPartner(partner);
        setEditablePayment({...editablePayment, partnerName: partner.partnerName, partnerId: partner.id})
    };

    const handleTyping = (e) => {
        const fieldName = e.target.name;
        let value = e.target.value;
        if (fieldName === "periodIncomeApproved" || fieldName === "incomePaidApproved") {
            value = value.replace(/\s/g, "");
            value = value.replace(",", ".");
            if (!isNumberStringWithTwoDecimalPlaces(value)) {
                return;
            }
            value = toNumber(value);
        }
        handleChangePayment(fieldName, value);
    };

    const handleChangePayment = (fieldName, value) => {
        setEditablePayment({...editablePayment, [fieldName]: value})
    };

    const saveOrUpdatePayment = () => {
        if (!selectedPartner || !selectedType || !editablePayment.incomePaidApproved || editablePayment.incomePaidApproved === 0) {
            return;
        }
        if (!editablePayment.id) {
            savePayment();
        } else {
            updatePayment();
        }
    };

    const savePayment = () => {
        const body = {
            partnerId: editablePayment.partnerId,
            type: editablePayment.type,
            income: editablePayment.incomePaidApproved,
            paymentData: editablePayment.paymentData,
            comment: editablePayment.comment
        };
        createPayment(body, () => afterSavePaymentCallback());
    };

   const updatePayment = () => {
        const body = {
            id: editablePayment.id,
            status: editablePayment.status,
            incomePayed: editablePayment.incomePaidApproved,
            periodIncomeApproved: editablePayment.periodIncomeApproved,
            comment: editablePayment.comment,
            paymentData: editablePayment.paymentData,
            paymentType: editablePayment.type
        };
        updatePaymentByManager(body, null, () => afterSavePaymentCallback())
    };


    const afterSavePaymentCallback = () => {
        loadTableData();
        handleClose();
    };

    const popover = () => {
        return (
                <Popover id={id}
                         open={open}
                         anchorEl={anchorEl}
                         onClose={handleClose}
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'right',
                         }}
                         transformOrigin={{
                             vertical: 'top',
                             horizontal: 'right',
                         }}
                >
                    <div style={{padding: "10px", textAlign: "center"}}>
                        {payment && payment.id ? "Редактирование выплаты" : "Начисление выплаты"}
                    </div>
                    <div style={{padding: "10px", maxWidth: "500px"}}>
                        <ManagerPaymentPopoverPaymentTypeSelect dropdownData={allPaymentTransferTypes}
                                                                onSelect={handleSelectType}
                                                                selectedValue={selectedType}
                                                                disabled={editablePayment.id && ![CALCULATED, PENDING].includes(editablePayment.status)}

                        />
                        <ManagerPaymentPopoverPartnerInput partnerName={editablePayment && editablePayment.partnerName}
                                                           partnersDropdownData={partners}
                                                           selectedPartner={selectedPartner}
                                                           onSelectPartner={handleSelectPartner}/>
                        <ManagerPaymentPopoverPaymentDetails editablePayment={editablePayment}
                                                             partnerPaymentDetailsPresets={partnerPaymentDetailsPresets}
                                                             partnerAdNetworkPresets={partnerAdNetworkPresets}
                                                             adNetworks={adNetworksDropdownData}
                                                             onChangeEditablePayment={handleChangePayment}
                                                             loadAdNetworkBalance={loadAdNetworkBalanceData}
                                                             adNetworkBalanceData={adNetworkBalanceData}
                                                             usdCurrencyRate={usdCurrencyRate}
                        />
                        <ManagerPaymentPopoverIncome isNewPayment={editablePayment.id === null}
                                                     editablePayment={editablePayment}
                                                     usdCurrencyRate={usdCurrencyRate}
                                                     onChangeEditablePayment={handleChangePayment}
                                                     balance={partnerBalance}
                        />
                        <TextField label="Комментарий"
                                   name="comment"
                                   value={editablePayment && editablePayment.comment ? editablePayment.comment : ""}
                                   onChange={handleTyping}
                                   InputLabelProps={{shrink: true}}
                                   style={{width: '100%', paddingBottom: '15px'}}/>
                        <br/>
                        <br/>
                        <div className="text-right">
                            <IconButton disableFocusRipple size="medium" onClick={saveOrUpdatePayment}
                                        variant="contained"
                                        title="Сохранить выплату">
                                <Done fontSize="medium"/>
                            </IconButton>
                            <IconButton disableFocusRipple size="medium" onClick={handleClose} variant="contained"
                                        title="Отмена">
                                <Cancel fontSize="medium"/>
                            </IconButton>
                        </div>
                    </div>
                </Popover>
        )
    };

    return (
        <>
            <div className={className} onClick={handleClick}>
                {button}
            </div>
            {anchorEl && popover()}

        </>
    );
}

const mapStateToProps = state => ({
    partners: state.managerPartnersReducer.partnersList,
    adNetworksDropdownData: state.advertisingNetworksReducer.adNetworksDropdownData,
    adNetworkBalanceData: state.advertisingNetworksReducer.adNetworkBalanceData,
    currencyRates: state.currencyRateReducer.actualCurrencyRates,
    partnerBalanceData: state.partnerBalanceReducer.managerPartnerBalanceData,
    managerPartnerBalanceDataLoading: state.partnerBalanceReducer.managerPartnerBalanceDataLoading,
    partnerPaymentDetailsPresets: state.preset.partnerPaymentDetailsPresets,
    partnerAdNetworkPresets: state.preset.partnerAdNetworkPresets,
});

export default connect(mapStateToProps,
    {
        loadManagerPartnerBalanceData,
        loadUserPresets,
        loadAllActiveNetworks,
        loadActualCurrencyRates,
        loadAdNetworkBalance,
        createPayment,
        updatePaymentByManager,
        savePreset
    })(ManagerPaymentPopover);