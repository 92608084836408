import React, {useEffect} from 'react';
import PartnersTableHeader from "./PartnersTableHeader";
import PartnersTableBody from "./PartnersTableBody";
import Spinner from "../../../common/Spinner";
import {getServerCurrentDate} from "../../../../utils/dateUtils";

const columns = [
    {name: "expandAction", label: "", sortingDisabled: true, width: "20px", },
    {name: "partnerActions", label: "", sortingDisabled: true, },
    {name: "partnerName", label: "Партнёр"},
    {name: "levelRus", label: "Уровень"},
    {name: "name", label: "ФИО"},
    {name: "registrationDate", label: "Дата регистрации", minWidth: "150px"},
    {name: "skype", label: "Скайп"},
    {name: "telegram", label: "Телеграм"},
    {name: "phone", label: "Телефон"},
    {name: "ref", label: "Ref"},
];

const levels = {
    NOVICE: "Новичок",
    MIDDLE: "Средний",
    TOP: "Топ",
};

const statuses = {
    NEW: "Создан аккаунт",
    EMAIL_CONFIRMED: "E-mail подтвержден",
    REGISTERED: "Зарегистринован",
    DECLINED: "Регистрация отклонена",
};

const xlsxColumns = [
    {name: "partnerName", title: "Партнёр", width: {wch: 50}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "name", title: "ФИО", width: {wch: 25}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "levelRus", title: "Уровень", width: {wch: 10}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "registrationStatus", title: "Статус", width: {wch: 17}, height: {wpx: 14}, dataFormatter: data => data && statuses[data] ? statuses[data] : ""},
    {name: "registrationDate", title: "Дата регистрации", width: {wch: 17}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "skype", title: "Скайп", width: {wch: 15}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "telegram", title: "Телеграм", width: {wch: 15}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "phone", title: "Телефон", width: {wch: 15}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "ref", title: "Ref", width: {wch: 10}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
];

export default function PartnersTable({tableData, reportData, toggleRowOpen, sort, sortingColumn, sortingDirection, isLoading, onTableScroll,
                                          approveRegistration, rejectRegistration, addOrRemoveFromFavorites, toggleCanOrderPayment, changeLevel,
                                          resendConfirmationEmail, getXlsxReportData}) {

    useEffect(() => {
        if (isLoading || !reportData) {
            return;
        }
        const dataset = xlsxDataSet();
        const date = getServerCurrentDate();
        const filename = "am-partners-" + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + "-" + date.getHours() + date.getMinutes();
        getXlsxReportData({filename:filename, dataset:dataset});
    }, [reportData]);

    const xlsxData = () => {
        return reportData && reportData.length > 0
            ? reportData.reduce((result, group) => {
            const groupColumn = [];
            xlsxColumns.forEach((column, idx) => {
                if (idx === 0) {
                    groupColumn.push({
                        value: group.partnerType,
                        style: {font: {sz: "12", bold: true}, alignment: {horizontal: "left"}}
                    });
                } else {
                    groupColumn.push({value: ""});
                }
            });
            result.push(groupColumn);
            const partnerRows = group.partners.map(partner => {
                const xlsxRow = [];
                xlsxColumns.forEach(column => {
                    xlsxRow.push({value: column.dataFormatter(partner[column.name]), style: {font: {sz: "11"}, alignment: {horizontal: "left"}}});
                });
                return xlsxRow;
            });
            return  [...result, ...partnerRows];
        }, [])
            : [];
    };

    const xlsxDataSet = () => {
        return [{columns: xlsxColumns, data: xlsxData()}]
    };

    return (
        <>
                <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={onTableScroll}
                     style={{height: 'inherit'}}>
                    <table className="table table-sm statistic_table sticky-header userselecttext" >
                       <PartnersTableHeader columns={columns} sort={sort} sortingColumn={sortingColumn} sortingDirection={sortingDirection}/>
                        { !isLoading && <PartnersTableBody columns={columns} tableData={tableData} toggleRowOpen={toggleRowOpen}
                                                           approveRegistration={approveRegistration} rejectRegistration={rejectRegistration}
                                                           addOrRemoveFromFavorites={addOrRemoveFromFavorites}
                                                           toggleCanOrderPayment={toggleCanOrderPayment}
                                                           changeLevel={changeLevel} resendConfirmationEmail={resendConfirmationEmail}/>}
                    </table>
                </div>
                { isLoading && <div><Spinner/></div> }
        </>
    );
}