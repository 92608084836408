import React from "react";
import {useSelector} from "react-redux";
import MaterialUIGroupedMultiSelect from "../selects/MaterialUIGroupedMultiSelect";


export default function AdminPartnersFilter({selectedPartners, handleChangeFilterData, isGroupSelectionDisabled, label='Партнёры',  inputStyle}) {
    const dropdownValues = useSelector(state => state.managerPartnersReducer.partnersFilterData);

    const onSelect = (value) => {
        handleChangeFilterData(value, true);
    };

    return (
        <MaterialUIGroupedMultiSelect
            styles={inputStyle}
            dropdownData={dropdownValues}
            groupDisplayedField='partnerType'
            groupDataField='partnerTypeId'
            values={selectedPartners}
            dataField='key'
            displayedField='partnerName'
            label={label}
            placeholder={'-'}
            onSelect={onSelect}
            isSearchable
            isGroupSelectionDisabled={isGroupSelectionDisabled}
        />
    );
}