import React, {Component} from "react";
import {connect} from "react-redux";
import {
    updatePaymentByManager,
    cancelPayment,
    clearPartnerPaymentsData,
    loadManagerPartnerPayments, massApprovePayments,
} from "../../../../actions/paymentsActions";
import BasePage from "../../BasePage";
import {fromDate, getIsoLocalString, toDate} from "../../../../utils/dateUtils";
import {
    ADVERTISING_NETWORKS,
    CALCULATED,
    CONFIRMATION,
    CURRENT_MONTH,
    DESC,
    LAST_MONTH,
    MANAGER_PAYMENTS,
    PAID,
    PENDING,
    TRANSFERRING_ERROR
} from "../../../../utils/constants";
import Spinner from "../../../common/Spinner";
import {addOrRemove} from "../../../../utils/arrayUtils";
import {searchByString} from "../../../../utils/searchUtils";
import {isNumberStringWithTwoDecimalPlaces} from "../../../../utils/validationUtils";
import {getMoneyString, toDouble, toDoubleOrNull} from "../../../../utils/formatUtils";
import {executeSync} from "../../../../utils/syncUtils";
import {loadPartnersFilterData} from "../../../../actions/managerPartnersActions";
import AdvertisingNetworksTable from "./tables/AdvertisingNetworksTable";
import {
    loadAdNetworkBalance,
    loadAllNetworks,
    saveOrUpdateNetwork
} from "../../../../actions/advertisingNetworkActions";
import {pathParameter, replaceQueryParam} from "../../../../utils/urlUtils";
import {URL_PAYMENTS_MANAGER} from "../../../../properties";
import {
    getApproveStatus, getOnlyWithPaymentDetailsDefaultValue
} from "../../../../utils/paymentUtils";
import {managerPaymentPageTabs} from "../../../../utils/pageTabsUtils";
import ManagerPaymentsFiler from "./filters/ManagerPaymentsFilter";
import ManagerPaymentsTable from "./tables/ManagerPaymentsTable";
import ManagerPaymentPopover from "./popover/ManagerPaymentPopover";
import paymentOrderImg from "../../../../resources/images/icons/atm.svg";
import PageContent from "../../../common/page/PageContent";
import {isEquals} from "../../../../utils/objectUtils";
import ActionPopover from "../../../common/popovers/ActionPopover";
import checkAll from "../../../../resources/images/icons/dcheck.svg";

const defaultSortingData = {
    [MANAGER_PAYMENTS]: {
        sortableColumn: "createDate",
        sortableDirection: DESC,
    },
    [ADVERTISING_NETWORKS]: {
        sortableColumn: "name",
        sortableDirection: DESC,
    }
};

class ManagerPaymentsPage extends Component {

    state = {
        selectedTab: MANAGER_PAYMENTS,
        filteredTableData: [],
        filters: {
            period: {
                from: fromDate(LAST_MONTH),
                to: toDate(CURRENT_MONTH),
            },
            statuses: null,
            paymentPeriod: null,
            incomeMin: "",
            incomeMax: "",
            partners: [],
            types:[],
            onlyWithPaymentDetails: getOnlyWithPaymentDetailsDefaultValue().value,
            paymentDetailsTypes: null,
        },
        sortableColumn: "period",
        sortableDirection: DESC,
        footerSearchValue: "",
        checkedRowIds: [],
        dataChanges: {}, //{1: {periodIncomeApproved: {newValue: "1v", invalid: true}, incomePaidApproved: {newValue: "2,0", invalid: false}, comment: {newValue: "comment", invalid: false}}}
        editableNetwork: {
            id: null,
            name: "",
            imageId: null,
            description: "",
            type: null,
            transferFeeOrBonus: "",
            active: false,
            hasChanges: false
        },
        editableNetworkErrors: [],
        xlsxReportData: null,
    };

    componentDidMount() {
        let pathParameterType = pathParameter("type");
        pathParameterType = pathParameterType ? pathParameterType : MANAGER_PAYMENTS;

        this.setState({selectedTab: pathParameterType});
        this.props.history.push({
            pathname: URL_PAYMENTS_MANAGER,
            search: replaceQueryParam("type", pathParameterType, window.location.search)
        });
        pathParameterType !== ADVERTISING_NETWORKS && this.props.loadManagerPartnerPayments(this.formatRequestBody(pathParameterType));
        this.props.loadPartnersFilterData();
        pathParameterType === ADVERTISING_NETWORKS && this.props.loadAllNetworks();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentTableData = (this.state.selectedTab === ADVERTISING_NETWORKS) ? this.props.allNetworks : this.props.managerPayments;
        const prevTableData = (this.state.selectedTab === ADVERTISING_NETWORKS) ? prevProps.allNetworks : prevProps.managerPayments;
        if ((currentTableData && currentTableData !== prevTableData) || (this.state.checkedRowIds !== prevState.checkedRowIds)
            || (this.state.footerSearchValue !== prevState.footerSearchValue) || (this.state.sortableColumn !== prevState.sortableColumn)
            || (this.state.sortableDirection !== prevState.sortableDirection) || (this.state.filters.incomeMin !== prevState.filters.incomeMin)
            || (this.state.filters.incomeMax !== prevState.filters.incomeMax)) {
            this.buildTableData();
        }
    }

    loadTableData = () => {
        if (this.props.isLoading) {
            return;
        }
        const selectedTab = this.state.selectedTab;
        selectedTab !== ADVERTISING_NETWORKS && this.props.loadManagerPartnerPayments(this.formatRequestBody(selectedTab));
        selectedTab === ADVERTISING_NETWORKS && this.props.loadAllNetworks();
    };

    clearAllFilters = () => {
        executeSync(() =>
            this.setState({
                ...this.state,
                filters: {
                    period: {
                        from: fromDate(LAST_MONTH),
                        to: toDate(CURRENT_MONTH),
                    },
                    status: null,
                    paymentPeriod: null,
                    incomeMin: "",
                    incomeMax: "",
                    partners: [],
                    types: [],
                    onlyWithPaymentDetails: getOnlyWithPaymentDetailsDefaultValue().value,
                    paymentDetailsTypes: null,
                },
                sortableColumn: defaultSortingData[this.state.selectedTab].sortableColumn,
                sortableDirection: defaultSortingData[this.state.selectedTab].sortableDirection,
                checkedRowIds: [],
                dataChanges: {}
            }))
            .then(() => this.loadTableData());
    };

    sort = (column, direction) => {
        this.setState({
            ...this.state,
            sortableColumn: column,
            sortableDirection: direction
        });
    };

    buildTableData = () => {
        let tableData = this.state.selectedTab === ADVERTISING_NETWORKS ? this.filterNetworksTableData() : this.filterPaymentsTableData();
        const {sortableColumn, sortableDirection} = this.state;
        const directionNumb = sortableDirection === DESC ? -1 : 1;
        tableData = tableData && [...tableData].sort((a, b) => {
            if (a[sortableColumn] === b[sortableColumn]) {
                return 0;
            }
            return a[sortableColumn] > b[sortableColumn] ? directionNumb : directionNumb * -1;
        });
        this.setState({...this.state, filteredTableData: tableData});
    };

    filterPaymentsTableData = () => {
        return searchByString(this.props.managerPayments, ["partnerName", "period", "payDate", "paymentDataValue", "paymentStatus['value']", "types"], this.state.footerSearchValue);
    };

    filterNetworksTableData = () => {
        return  searchByString(this.props.allNetworks, ["name", "type['value]", "description"], this.state.footerSearchValue);
    };

    selectTab = (tabName) => {
        this.props.history.push({
            pathname: URL_PAYMENTS_MANAGER,
            search: replaceQueryParam("type", tabName, window.location.search)
        });
        executeSync(
            () => this.setState(
                {
                    selectedTab: tabName,
                    filteredTableData: [],
                    filters: {
                        ...this.state.filters,
                        incomeMin: "",
                        incomeMax: "",
                    },
                    sortableColumn: defaultSortingData[tabName].sortableColumn,
                    sortableDirection: defaultSortingData[tabName].sortableDirection,
                    checkedRowIds: [],
                }))
            .then(() => this.loadTableData());
    };

    setFilterData = (fieldName, value) => {
        if (fieldName === "paymentPeriod" && value === this.state.filters.paymentPeriod) {
            value = null;
        }
        const filters = {...this.state.filters, [fieldName]: value};
        const checkedRowIds = (fieldName === "statuses") ? [] : this.state.checkedRowIds;
        const newState = {filters: filters, checkedRowIds: checkedRowIds};
        if (["statuses", "paymentPeriod", "onlyWithPaymentDetails", "period", "partners", "types", "paymentDetailsTypes"].includes(fieldName)) {
            executeSync(() => this.setState(newState)).then(() => this.loadTableData());
        } else {
            this.setState(newState);
        }

    };

    selectDate = (fieldName, date) => {
        if (this.props.isLoading) {
            return;
        }
        this.state.filters.period[fieldName] = date;
        this.setFilterData("period", this.state.filters.period);
    };

    handleTypingMoneyString = (fieldName, value) => {
        executeSync(() =>  this.setState({filters: {...this.state.filters, [fieldName]: value}})).then(() => this.loadTableData());

    };

    toggleCheckAllRows = () => {
        let checkedRowIds = this.state.checkedRowIds;
        const tableData = this.state.filteredTableData;
        if (checkedRowIds.length === 0 || checkedRowIds.length < tableData.length) {
            checkedRowIds = tableData.map(item => item.id);
        } else {
            checkedRowIds = [];
        }
        this.setState({checkedRowIds: checkedRowIds})
    };

    toggleCheckRow = (id) => {
        const checkedRowIds = addOrRemove(this.state.checkedRowIds, id);
        this.setState({checkedRowIds: checkedRowIds})
    };

    changeSearchValue = (value) => {
        this.setState({footerSearchValue: value});
    };

    handleEditTableCell = (newValue, rowData, column) => {
        const originalValue = rowData[column.name];
        newValue = newValue.replace(/&nbsp;/g, ' ');
        const {dataChanges} = this.state;
        const formattedOldValue = getMoneyString(originalValue);
        if (newValue === formattedOldValue) {
            if (dataChanges[rowData.id] && dataChanges[rowData.id][column.name]) {
                dataChanges[rowData.id][column.name] = undefined;
                this.setState({dataChanges: {...dataChanges, [rowData.id]: dataChanges[rowData.id]}});
            }
            return;
        }
        if (!dataChanges[rowData.id]) {
            dataChanges[rowData.id] = {};
        }
        let invalid = false;
        if (column.name !== "comment") {
            newValue = newValue.replace(/\s/g, "");
            invalid = !isNumberStringWithTwoDecimalPlaces(newValue);
        }
        dataChanges[rowData.id][column.name] = {newValue: newValue, invalid: invalid};
        this.setState({dataChanges: {...dataChanges, [rowData.id]: dataChanges[rowData.id]}})
    };

    getXlsxData = (data) => {
        if (!data || isEquals(data, this.state.xlsxReportData)) {
            return;
        }
        this.setState({xlsxReportData: data});
    };

    formatRequestBody = (selectedTab) => {
        if (!this.state.filters) {
            return {};
        }
        const {period, statuses, paymentPeriod, onlyWithPaymentDetails, partners, types, incomeMin, incomeMax, paymentDetailsTypes} = this.state.filters;
        return {
            from: getIsoLocalString(period.from),
            to: getIsoLocalString(period.to),
            statuses: statuses && statuses.map(status => status.value),
            paymentPeriod: paymentPeriod,
            types: types && types.map(type => { return { paymentType : type.value, isAdvance : type.isAdvance }}),
            partnerIds: partners
                && partners.map(partner => !partner.isGroup
                    ? partner.id
                    : this.props.partnersFilterData.filter(group => group.partnerTypeId === partner.partnerTypeId).flatMap(group => group.options.map(partner => partner.id))
                ).reduce((resultArray, currentValue) => Array.isArray(currentValue) ? [...resultArray, ...currentValue] : [...resultArray, currentValue], []),
            incomeMin: incomeMin !== "" ? parseInt(incomeMin.replace(/\s/g, "")) : null,
            incomeMax: incomeMax !== "" ? parseInt(incomeMax.replace(/\s/g, "")) : null,
            onlyWithPaymentDetails: onlyWithPaymentDetails,
            paymentTypes: paymentDetailsTypes?.map(paymentDetailsType => paymentDetailsType.value),
        }
    };

    cancelPayment = (payment) => {
        const paymentChanges = this.state.dataChanges[payment.id];
        const body = {
            paymentId: payment.id,
            comment: (paymentChanges && paymentChanges.comment && paymentChanges.comment.newValue)
                ? paymentChanges.comment.newValue : payment.comment
        };
        this.props.cancelPayment(body, () => this.afterEditPaymentCallback(payment));
    };

    getApproveRequestBodyItem = (payment) => {
        const newStatus = getApproveStatus(payment.status);
        const paymentChanges = this.state.dataChanges && this.state.dataChanges[payment.id];
        const periodIncomeApproved = (paymentChanges && paymentChanges.periodIncomeApproved && paymentChanges.periodIncomeApproved.newValue)
            ? toDoubleOrNull(paymentChanges.periodIncomeApproved.newValue) : payment.periodIncomeApproved;
        const incomePaid = (paymentChanges && paymentChanges.incomePaidApproved && paymentChanges.incomePaidApproved.newValue)
            ? toDoubleOrNull(paymentChanges.incomePaidApproved.newValue) : payment.incomePaidApproved;
        const comment = (paymentChanges && paymentChanges.comment && paymentChanges.comment.newValue)
            ? paymentChanges.comment.newValue : payment.comment;
        return {
            id: payment.id,
            status: newStatus,
            incomePayed: incomePaid,
            periodIncomeApproved: periodIncomeApproved,
            comment: comment,
        };
    };

    approvePayment = (payment) => {
        if (!payment) {
            return;
        }
        const body = this.getApproveRequestBodyItem(payment);
        const successMsg =
            body.status === PENDING || body.status === CONFIRMATION
            ? 'Передано на оплату'
            : 'Оплата подтверждена';
        this.props.updatePaymentByManager(body, successMsg, () => this.afterEditPaymentCallback(payment))
    };

    updatePayment = (payment) => {
        if (!payment) {
            return;
        }
        const successMsg = 'Выплата обновлена';
        const body = {
            id: payment.id,
            status: payment.status,
            incomePayed: payment.incomePaidApproved ? toDouble(payment.incomePaidApproved, 2) : 0,
            periodIncomeApproved: payment.periodIncomeApproved ? toDouble(payment.periodIncomeApproved, 2) : 0,
            comment: payment.comment,
            paymentData: payment.paymentData
        };
        this.props.updatePaymentByManager(body, successMsg, () => this.afterEditPaymentCallback(payment))
    };

    afterEditPaymentCallback = (payment) => {
        this.loadTableData();
        const dataChanges = this.state.dataChanges;
        if (dataChanges[payment.id]) {
            dataChanges[payment.id] = undefined;
            this.setState({dataChanges: dataChanges})
        }
    };

    massApprovePayments = () => {
        const {checkedRowIds} = this.state;
        if (!checkedRowIds || checkedRowIds.length === 0) {
            return;
        }
        const requestBody = this.props.managerPayments
            .filter(payment => checkedRowIds.includes(payment.id))
            .map(payment => this.getApproveRequestBodyItem(payment));
        this.props.massApprovePayments(requestBody,
            () => {
                this.loadTableData();
                const dataChanges = this.state.dataChanges;
                requestBody.forEach(payment => {
                    if (dataChanges[payment.id]) {
                        dataChanges[payment.id] = undefined;
                    }
                });
                this.setState({dataChanges: dataChanges, checkedRowIds: []})
            })
    };

    sumFooterWithChanges = (fieldName) => {
        const {filteredTableData, dataChanges} = this.state;
        const dataWithChanges = filteredTableData.map(item => {
            let changes = dataChanges[item.id] && dataChanges[item.id][fieldName] && dataChanges[item.id][fieldName].newValue;
            if (changes) {
                changes = changes.replace(/&nbsp;/g, ' ');
                changes = toDoubleOrNull(changes);
            }
            return changes ? changes : item[fieldName];
        });
        const result = dataWithChanges.reduce((a, b) => a + b, 0);
        return getMoneyString(result);
    };

    setEditableNetwork = (editableNetwork) => {
        this.setState({editableNetwork: editableNetwork});
    };

    clearEditableNetwork = () => {
        this.setEditableNetwork({
            id: null,
            name: "",
            imageId: null,
            description: "",
            type: null,
            transferFeeOrBonus: "",
            active: false,
            hasChanges: false
        })
    };

    saveOrUpdateNetwork = () => {
        this.props.saveOrUpdateNetwork(this.state.editableNetwork, () => this.afterSaveNetworkCallback());
    };

    afterSaveNetworkCallback = () => {
        this.props.loadAllNetworks();
        this.clearEditableNetwork();
    };

    getApproveButtonTooltipValue = (status) => {
        if (!status) {
            return "";
        }
        switch (status) {
            case CALCULATED:
            case TRANSFERRING_ERROR:
                return "Передать на оплату";
            case PENDING:
                return "Подтвердить оплату";
            default:
                return ""
        }
    };

    renderContent = () => {
        const {filteredTableData, dataChanges, selectedTab, filters, checkedRowIds, sortableColumn, sortableDirection} = this.state;
        if (this.props.isLoading) {
            return <Spinner/>;
        } else if (selectedTab === MANAGER_PAYMENTS) {
          return <ManagerPaymentsTable
                toggleCheckRow={this.toggleCheckRow}
                toggleCheckAllRows={this.toggleCheckAllRows}
                checkedRowIds={checkedRowIds}
                tableData={filteredTableData}
                tableDataChanges={dataChanges}
                handleEditCell={this.handleEditTableCell}
                sort={this.sort}
                sortableColumn={sortableColumn}
                sortableDirection={sortableDirection}
                cancelPayment={this.cancelPayment}
                approvePayment={this.approvePayment}
                updatePayment={this.updatePayment}
                massApprovePayments={this.massApprovePayments}
                selectedStatuses={filters.statuses && filters.statuses.map(status => status.value)}
                sumFooterWithChanges={this.sumFooterWithChanges}
                loadTableData={this.loadTableData}
                getXlsxReportData={this.getXlsxData}
                getApproveButtonTooltipValue={this.getApproveButtonTooltipValue}
            />;
        } else if (selectedTab === ADVERTISING_NETWORKS) {
            return <AdvertisingNetworksTable allNetworks={filteredTableData}
                                      editableNetwork={this.state.editableNetwork}
                                      setEditableNetwork={this.setEditableNetwork}
                                      errorFields={this.state.editableNetworkErrors}
                                      saveOrUpdateNetwork={this.saveOrUpdateNetwork}
                                      clearEditableNetwork={this.clearEditableNetwork}
                                      sort={this.sort}
                                      sortableColumn={sortableColumn}
                                      sortableDirection={sortableDirection}/>;
        }
    };

    renderFilters = () => {
        return this.state.selectedTab === MANAGER_PAYMENTS
            && <ManagerPaymentsFiler
                filters={this.state.filters}
                setFilterData={this.setFilterData}
                checkedRowIds={this.state.checkedRowIds}
                loadTableData={this.loadTableData}
                clearAllFilters={this.clearAllFilters}
                selectDate={this.selectDate}
                onTypingMoneyString={this.handleTypingMoneyString}
            />
    };

    renderAdditionalButtons = () => {
        return (
            <>
                {this.renderMassApproveButton()}
                {this.renderPaymentCreateButton()}
            </>
        );
    };

    renderPaymentCreateButton = () => {
        return this.state.selectedTab === MANAGER_PAYMENTS
            && <ManagerPaymentPopover className="filters-btn"
            button={<a href="#" className="additional-filters-button" title="Создать выплату">
                <img src={paymentOrderImg} alt=""/></a>}
            loadTableData={this.loadTableData}
        />;
    };

    renderMassApproveButton = () => {
        const {filters, checkedRowIds} = this.state;
        const selectedStatuses = filters.statuses && filters.statuses.map(status => status.value);
        return selectedStatuses && selectedStatuses.length === 1 && selectedStatuses[0] !== PAID && <ActionPopover
            button={
                <div className="filters-btn">
                <a href="#" className="additional-filters-button"
                   title={this.getApproveButtonTooltipValue(selectedStatuses[0]) + ' выбранные позиции'}>
                    <img src={checkAll} alt=""/>
                </a>
                </div>
            }
            tooltip={this.getApproveButtonTooltipValue(selectedStatuses[0]) + ' выбранные позиции'}
            popoverLocation='right'
            label={this.getApproveButtonTooltipValue(selectedStatuses[0]) + ' выбранные позиции?'}
            handleSubmit={() => this.massApprovePayments()}
            disabled={!checkedRowIds || checkedRowIds.length === 0}
        />
    };

    render() {
        const {filteredTableData, selectedTab, xlsxReportData} = this.state;
        return <BasePage pageName="Финансы"
                         content={
                             <PageContent
                                 displayContentHeader
                                 displaySearchInput
                                 tabs={managerPaymentPageTabs}
                                 selectedTabName={selectedTab}
                                 onSelectTab={this.selectTab}
                                 displayFiltersBtn={selectedTab === MANAGER_PAYMENTS}
                                 displayXlsxBtn={selectedTab === MANAGER_PAYMENTS}
                                 xlsxData = {xlsxReportData}
                                 onChangeSearchInput = {this.changeSearchValue}
                                 searchInputAmount={filteredTableData?.length}
                                 beforeSearchInputAmount={selectedTab === MANAGER_PAYMENTS ? this.props.managerPayments?.length : this.props.allNetworks?.length}
                                 headerAdditionalEl={this.renderAdditionalButtons()}
                                 contentEl={ this.renderContent() }
                                 filtersEl={ this.renderFilters() }
                                />}
             />;
    }
}

function mapStateToProps(state) {
    return {
        isLoading: state.paymentsReducer.managerPaymentsLoading,
        managerPayments: state.paymentsReducer.managerPayments,
        partnersFilterData: state.managerPartnersReducer.partnersFilterData,
        allNetworks: state.advertisingNetworksReducer.allNetworks,
    }
}

export default connect(mapStateToProps, {
    loadManagerPartnerPayments,
    clearPartnerPaymentsData,
    cancelPayment,
    updatePaymentByManager,
    massApprovePayments,
    loadPartnersFilterData,
    loadAllNetworks,
    saveOrUpdateNetwork,
    loadAdNetworkBalance,
})(ManagerPaymentsPage);

