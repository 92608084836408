import React from "react";
import {useSelector} from "react-redux";
import MaterialUIMultiSelect from "../../../common/selects/MaterialUIMultiSelect";
import {isMobileScreen} from "../../../../utils/displayUtils";

export default function CountriesFilter({selectedCountries, handleChangeFilterData, inputStyle}) {
    const dropdownValues = useSelector(state => state.dictionaries.activeCountries);
    const isMobile = isMobileScreen();

    const onSelect = (value) => {
        handleChangeFilterData("countries", value, true);
    };

    return (
            <MaterialUIMultiSelect
                styles={inputStyle}
                dropdownData={dropdownValues}
                values={selectedCountries}
                dataField='countryId'
                displayedField='countryName'
                label={'Страны'}
                placeholder={'-'}
                onSelect={onSelect}
                isSearchable={!isMobile}
            />
    );
}