import React from "react";

import xlsxIcon from "../../../../resources/images/icons/xls.png";
import allXlsxIcon from "../../../../resources/images/icons/all-xls.png";
import ExportToXlsx from "../../../common/xlsx/ExportToXlsx";
import {getServerCurrentDate} from "../../../../utils/dateUtils";

const xlsxColumns = [
    {name: "date", title: "Дата добавления", width: {wch: 20}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "domain", title: "Домен", width: {wch: 25}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "siteId", title: "ИД", width: {wch: 30}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "violation", title: "Нарушение", width: {wch: 25}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "isPreLandAllowed", title: "Можно преленд", width: {wch: 20}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
    {name: "adNetwork", title: "Рекл.сетка", width: {wch: 20}, height: {wpx: 14}, dataFormatter: data => data ? data : ""},
];

export default function BlackListActionButtons({allData, filteredData}) {

    const date = getServerCurrentDate();
    const dateString = date.getFullYear() + (date.getMonth() + 1) + date.getDate() + "-" + date.getHours() + date.getMinutes();

    const xlsxData = (data) => {
        return data && data.map((row) => {
            const xlsxRow = [];
            xlsxColumns.forEach((column, colIdx) => {
                const horizontalAlignment = (colIdx === 0) ? "center" : "right";
                xlsxRow.push({
                    value: column.formatXlsxBodyCell ? column.formatXlsxBodyCell(row[column.name], row) : row[column.name],
                    style: {
                        font: {sz: "11"},
                        alignment: {horizontal: horizontalAlignment},
                        numFmt: column.xlsxNumFormat && column.xlsxNumFormat(row[column.name])
                    }
                });
            });
            return xlsxRow;
        })
    };


    return <div >
        <div className="circle-btn-group" style={{position: "absolute", bottom: "0", backgroundColor: "white"}}>
            <button className="circle-btn" title="Скачать весь blacklist JSON">
                <i className="ti ti ti-cloud-down" onClick={()=> window.open(process.env.REACT_APP_API_URL + '/blacklist')}/>
            </button>
            <ExportToXlsx name={"am-blacklist-all-" + dateString} dataSet={[{columns: xlsxColumns, data: xlsxData(allData)}]} button={
                <button className="circle-btn" title="Скачать весь blacklist XLSX">
                    <img src={allXlsxIcon} className="circle-xls" alt=""/>
                </button>
            }/>
            <ExportToXlsx name={"am-blacklist-filtered-" + dateString} dataSet={[{columns: xlsxColumns, data: xlsxData(filteredData)}]} button={
                <button className="circle-btn" title="Скачать XLSX выбранной рекл.сети">
                    <img src={xlsxIcon} className="circle-xls" alt=""/>
                </button>
            }/>
        </div>
    </div>
}