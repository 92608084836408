import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";


export default function OffersCountryFilter({offers, selectedCountries, onSelectCountry, inputStyle}) {
    const countries = useSelector(state => state.dictionaries.countries);
    const activeCountries = useSelector(state => state.dictionaries.activeCountries);

    const [dropdownValues, setDropdownValues] = useState([]);


    useEffect(() => {
        const getFilteredCountries = () => {
            let resultCountries = [];
            if (activeCountries && offers) {
                activeCountries.forEach(function (country) {
                    const found = offers.find(function (offer) {
                        return country.operatorIds.includes(offer.operatorId);
                    });
                    if (found) {
                        resultCountries.push(country);
                    }
                })
            }
            return resultCountries;
        };
        setDropdownValues(getFilteredCountries());
    }, [offers, countries, selectedCountries]);

    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedCountries}
                               dataField='countryId' displayedField='countryName' label='Страны'
                               placeholder='-'
                               onSelect={onSelectCountry}/>
    )
}


