import React, {Component} from 'react';
import {connect} from "react-redux";
import {loadPartnerProjectsWithNew, selectProject} from "../../../../actions/projectsActions";
import {deleteSmartLink, loadPartnerSmartLink, loadSmartLinkUrl} from "../../../../actions/partnerSmartLinkActions";
import {showWarning} from "../../../../actions/warningActions";
import SmartLinkEditingTable from "./SmartLinkEditingTable";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import ProjectCreationCollapse from "../../../common/projectCreation/ProjectCreationCollapse";
import PropTypes from "prop-types";
import PageContent from "../../../common/page/PageContent";
import {billingTypesData} from "../../../../utils/offerUtils";


class SmartLinkEditingComponent extends Component {

    static propTypes = {
        smartLinkId: PropTypes.any,
        displayedComponent: PropTypes.string,
        displayComponent: PropTypes.func,
        returnToPrevComponent: PropTypes.func,
        selectedProject: PropTypes.any,
        onSelectProject: PropTypes.func,
        onExcludeOffer: PropTypes.func,
        onShowEditSmartLinkComponent: PropTypes.func,
        deleteSmartLink: PropTypes.func,
        showSmartLinkOffersChangingContent: PropTypes.func,
        saveSmartLink: PropTypes.func,
        selectedBillingType: PropTypes.any,
        onSelectBillingType: PropTypes.func,
    };

    state = {
        smartLink: null,
        isFormExpanded: false,
        isProjectSelected: false,
        width: 0,
        selectedDomain: null,
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.setState({width: this.container.offsetWidth});
        const {smartLinkId, smartLink} = this.props;
        this.props.loadPartnerProjectsWithNew();
        if (!smartLink || smartLink.id !== smartLinkId) {
            this.props.loadPartnerSmartLink(smartLinkId);
        }
        if (smartLink) {
            this.props.loadSmartLinkUrl(smartLink.id, smartLink.projectId);
        }
        if (this.props.domains) {
            const defaultDomain = this.props.domains.find(domain => domain.defaultDomain);
            this.selectDomain(defaultDomain);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.smartLink && this.props.smartLink !== prevProps.smartLink) {
            const billingType = this.props.smartLink.smartLinkOffers?.[0].billingType;
            const billingTypeDataItem = billingType && billingTypesData.find(dataItem => dataItem.type === billingType);
            this.props.onSelectBillingType(billingTypeDataItem);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (e) => {
        if (this.container.offsetWidth !== this.state.width) {
            this.setState({width: this.container.offsetWidth})
        }
    };


    returnToPrevComponent = () => {
        if (this.props.smartLink.hasChanges) {
            this.props.saveSmartLink("Смартлинк изменён. Выйти с сохранением?",
                () => this.props.returnToPrevComponent());
        } else {
            this.props.returnToPrevComponent();
        }
        if (!this.props.selectedProject || this.props.selectedProject.id === -1) {
            selectProject(this.props.projects[0])
        }
    };

    onChangeTableState = () => {
        this.setState({smartLink: this.props.smartLink})
    };

    changeSmartLinkOffers = () => {
        if (this.props.smartLink && this.props.smartLink.smartLinkOffers) {
            this.props.showSmartLinkOffersChangingContent(this.props.smartLink);
        }
    };

    selectProject = (project) => {
        const smartLink = this.props.smartLink;
        if (project && project.id !== -1 && project.id !== smartLink.projectId && smartLink.status !== "GLOBAL") {
            this.props.smartLink.hasChanges = true;
        }
        const isExpanded = project && project.id === -1;
        this.setState({isFormExpanded: isExpanded, isProjectSelected: true});
        this.props.onSelectProject(project);
        if (!isExpanded) {
            project && this.props.loadSmartLinkUrl(smartLink.id, project.id);
        }
    };

    hideForm = () => {
        this.setState({isFormExpanded: false, isProjectSelected: false});
    };

    onCreateProject = (createdProject) => {
        this.props.loadSmartLinkUrl(this.props.smartLink.id, createdProject.id);
    };

    deleteSmartLink = (smartLink) => {
        this.props.showWarning("Удалить смарлинк?", () => this.props.deleteSmartLink(smartLink, this.props.returnToPrevComponent()));
    };

    selectDomain = (domain) => {
        this.setState({selectedDomain: domain})
    };

    render() {
        const {projects, smartLink, smartLinkUrl, selectedProject, domains, selectedBillingType} = this.props;
        const {isProjectSelected, selectedDomain} = this.state;
        const canBeEdited = smartLink && smartLink.status !== "GLOBAL";
        const smartLinkProject = isProjectSelected ? selectedProject : ((projects && smartLink) ? projects.find(project => project.id === smartLink.projectId) : null);

        const projectsFilter =
            <MaterialUISelect
                styles={{root: {minWidth: 180}}}
                dropdownData={projects}
                displayedFields={['sourceId', 'name']} dataField='id'
                placeholder="Выберите поток"
                selectedValue={smartLinkProject}
                onSelectCallbackFunction={(project) => this.selectProject(project)}
                isSearchable={true}/>;

        const domainsFilter = domains && domains.length > 0
            && <MaterialUISelect
                styles={{root: {minWidth: 180}}}
                dropdownData={domains}
                displayedFields={['id', 'cname']} dataField='id'
                placeholder="Выбрать домен"
                selectedValue={selectedDomain}
                onSelectCallbackFunction={(domain) => this.selectDomain(domain)}
                isSearchable={true}/>;

        const billingTypeInput =
            selectedBillingType && <MaterialUISelect
                styles={{root: {minWidth: 120}}}
                dropdownData={[]}
                displayedField="fullName" dataField='type'
                placeholder="Тип выплаты"
                selectedValue={selectedBillingType}
                isDisabled/>;

        const returnBtn =
            <button className="circle-btn" title="Назад"
                    onClick={this.returnToPrevComponent}>
                <i className="ti ti-back-left"/>
            </button>;

        const desktopForm =
            <div style={{paddingTop: "10px"}}>
                <div className="row">
                    <div className="row col-lg-11 col-md-10 col-sm-10 col-xs-12 col" style={{display: "-webkit-inline-box"}}>
                        <div style={{paddingLeft: "10px"}}>
                            {projectsFilter}
                        </div>
                        <div style={{paddingLeft: "10px"}}>
                            {domainsFilter}
                        </div>
                        <div style={{paddingLeft: "10px"}}>
                            {billingTypeInput}
                        </div>
                        {!this.state.isFormExpanded && canBeEdited &&
                        <div className="select-potok" style={{maxHeight: '40px'}}>
                            <button className="btn btn-outline-primary btn-margin select-potok"
                                    onClick={this.changeSmartLinkOffers}>Изменить набор офферов
                            </button>
                            <button className="btn btn-outline-success btn-margin"
                                    onClick={() => this.props.saveSmartLink("Сохранить изменения?")}>Сохранить
                            </button>
                        </div>
                        }
                    </div>
                    <div className="col-lg-1 col-md-2 col-sm-2 col-xs-12 right-buttons no_left_right_padding">
                        {returnBtn}
                    </div>
                </div>
            </div>;

        const mobileForm =
            <div className="row">
                <div className="row col-sm-12 col-12 right-buttons no_left_right_padding">
                    {returnBtn}
                </div>
                <div className="col-sm-12 col-12" style={{paddingTop: "10px"}}>
                    {projectsFilter}
                </div>
                <div className="col-sm-12 col-12" style={{paddingTop: "10px"}}>
                    {domainsFilter}
                </div>
                <div className="col-sm-12 col-12" style={{paddingTop: "10px"}}>
                    {billingTypeInput}
                </div>
                {!this.state.isFormExpanded && canBeEdited &&
                <div className="row col-12 buttons-block btn-block">
                    <button className="btn btn-outline-primary"
                            onClick={this.changeSmartLinkOffers}>Выбрать офферы
                    </button>
                    <button className="btn btn-outline-success"
                            onClick={() => this.props.saveSmartLink("Сохранить изменения?")}>Сохранить
                    </button>
                </div>
                }
            </div>;


        const tables = <SmartLinkEditingTable smartLink={smartLink} smartLinkUrl={smartLinkUrl}
                                              onChangeTableState={this.onChangeTableState}
                                              onDelete={this.deleteSmartLink}
                                              selectedDomain={this.state.selectedDomain}/>;

        return (
            <PageContent
                contentEl={
                    <>
                        {this.state.width > 659 ? desktopForm : mobileForm}
                        <ProjectCreationCollapse isExpanded={this.state.isFormExpanded}
                                                 onCreateProjectCallbackFunction={this.onCreateProject}
                                                 onHideForm={this.hideForm}
                                                 onSelectCreatedProject={this.selectProject}/>
                        {tables}
                        <div ref={el => (this.container = el)}/>
                    </>
                }
            />

        );
    }

};


function mapStateToProps(state) {
    return {
        projects: state.projects.projects,
        smartLink: state.partnerSmartLinks.editableSmartLink,
        smartLinkUrl: state.partnerSmartLinks.editableSmartLinkUrl,
        domains: state.partnerDomainsReducer.partnerDomains,
    }
}

export default connect(mapStateToProps, {
    loadPartnerProjectsWithNew,
    deleteSmartLink,
    loadPartnerSmartLink,
    loadSmartLinkUrl,
    showWarning,
})(SmartLinkEditingComponent);