import React, {Fragment} from "react";
import ActionPopover from "../popovers/ActionPopover";

export default function CircleButtonsGroup({buttons=[{isSelected: false, title: "", confirmMessage: null, iconClassName: "", onClick: () => {}}]}) {
    return (
        <div className="circle-btn-group">
            {buttons.map(btn => <Fragment key={btn.title}>
                {
                    btn.confirmMessage
                        ?  <ActionPopover
                                          button={
                                              <button className={btn.isSelected ? "circle-btn selected" : "circle-btn"} title={btn.title}>
                                                  <i className={btn.iconClassName}/>
                                              </button>
                                          }
                                          tooltip={btn.confirmMessage}
                                          popoverLocation='top'
                                          label={btn.confirmMessage}
                                          handleSubmit={btn.onClick}/>
                        : <button className={btn.isSelected ? "circle-btn selected" : "circle-btn"} title={btn.title}
                                  onClick={btn.onClick}><i className={btn.iconClassName}/>
                        </button>
                }
                </Fragment>
            )}
        </div>
    )
}


