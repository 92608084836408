import axios from "axios";
import {returnErrors} from "./errorActions";
import {getConfig} from './authActions';
import {SERVICE_GROUPS_LOADED, SERVICE_GROUPS_LOADING} from "./actionTypes";


export const loadServiceGroups = () => (dispatch, getState) => {
    dispatch({type: SERVICE_GROUPS_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/services/grouped', getConfig(getState))
        .then(res =>
            dispatch({
                type: SERVICE_GROUPS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};
