import {
    MANAGER_ACTIVE_OFFERS_LOADED, MANAGER_ALL_OFFERS_LOADED, MANAGER_ALL_OFFERS_LOADING,
    MANAGER_DEPRECATED_OFFERS_LOADED,
    MANAGER_INACTIVE_OFFERS_LOADED,
    MANAGER_OFFER_ACTIVATED, MANAGER_OFFER_CHILDREN_LOADED,
    MANAGER_OFFER_DEACTIVATED, MANAGER_OFFER_DELETED, MANAGER_OFFER_DEPRECATED, MANAGER_OFFER_SAVED,
    MANAGER_OFFERS_LOADING,
    OFFER_CARD_DATA_LOADED,
} from "../../actions/actionTypes";


const initialState = {
    allOffers: [],
    activeOffers: [],
    inactiveOffers: [],
    deprecatedOffers: [],
    offerCardData: null,
    loading: false,
    allOfferLoading: false,
    children: null,
};


export default function (state = initialState, action = {}) {
    switch (action.type) {
        case MANAGER_OFFERS_LOADING:
            return {
                ...state,
                loading: true
            };
        case MANAGER_ACTIVE_OFFERS_LOADED:
            return {
                ...state,
                activeOffers: action.payload,
                loading: false
            };
        case MANAGER_INACTIVE_OFFERS_LOADED:
            return {
                ...state,
                inactiveOffers: action.payload,
                loading: false
            };
        case MANAGER_DEPRECATED_OFFERS_LOADED:
            return {
                ...state,
                deprecatedOffers: action.payload,
                loading: false
            };
        case MANAGER_ALL_OFFERS_LOADING:
            return {
                ...state,
                allOfferLoading: true
            };
        case MANAGER_ALL_OFFERS_LOADED:
            return {
                ...state,
                allOfferLoading: false,
                allOffers: action.payload,
            };
        case OFFER_CARD_DATA_LOADED:
            return {
                ...state,
                offerCardData:action.payload,
                loading: false
            };
        case MANAGER_OFFER_DEPRECATED:
        case MANAGER_OFFER_DELETED:
        case MANAGER_OFFER_SAVED:
            return {
                ...state,
                offerCardData: action.payload,
                loading: false
            };
        case MANAGER_OFFER_CHILDREN_LOADED:
            return {
                ...state,
                children: action.payload
            };
        default:
            return state;

    }
}