import React from "react";
import FiltersCard from "../../../../FiltersCard";
import PaymentsDateRangePicker from "./PaymentsDateRangePicker";
import PaymentsPeriodsFilter from "./PaymentsPeriodsFilter";

export default function PartnerPaymentsFilters(props) {

    const filters = () => {
        const {period, fromDate, toDate} = props.filters;
        return (
            <div className="row">
                <PaymentsDateRangePicker fromDate={fromDate} toDate={toDate} selectFromDate={props.selectFromDate}
                                         selectToDate={props.selectToDate}
                                         inputStyle={props.inputStyle}
                />
                <PaymentsPeriodsFilter selectPeriod={props.selectPeriod} selectedPeriod={period}/>
            </div>
        );
    };

    const buttons = () => {
        return (
            <div className="circle-btn-group">
                <button className={props.showTable ? "circle-btn selected" : "circle-btn"}
                        title="Сформировать отчет" onClick={props.buildTable}><i className="ti ti-check stat-icon"/>
                </button>
                <button className="circle-btn" title="Очистить" onClick={props.clearData}><i className="ti ti-close stat-icon"/>
                </button>
            </div>
        );
    };

    return <FiltersCard filters={filters()} buttons={buttons()}/>
}
