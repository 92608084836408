import React, {useEffect, useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import SimpleTable from "../../../common/tables/SimpleTable";
import Spinner from "../../../common/Spinner";
import {connect, useSelector} from "react-redux";
import {returnErrors} from "../../../../actions/errorActions";
import {CLICKS, REFERRALS_AMOUNT, TRAFFICBACKS} from "../../../../utils/constants";
import useDelayedSearch from "../../../../hooks/useDelayedSearch";

const NUMBER_OF_ROWS_TO_RENDER = 50;

const tableColumns = (statisticType) => {
    return statisticType === REFERRALS_AMOUNT
        ? referralsTableColumns()
        : clicksTableColumns(statisticType);
};

const clicksTableColumns = (statisticType) => {
    let columns = [
        {name: "Дата, время", key: "dateTime", getBodyCellValue: click => click.dateTime, headerCellStyle: {minWidth: "110px", width: "110px"}},
        {name: "pt_id", key: "ptId", getBodyCellValue: click => click.ptId,},
        {name: "IP", key: "ip", getBodyCellValue: click => click.ip,},
        {name: "Браузер", key: "browser", getBodyCellValue: click => click.browser, headerCellStyle: {minWidth: "100px"}},
        {name: "ОС", key: "os", getBodyCellValue: click => click.os, headerCellStyle: {minWidth: "100px"}},
        {name: "Устройство", key: "deviceName", getBodyCellValue: click => click.deviceName, headerCellStyle: {minWidth: "130px"}},
        {name: "User agent", key: "userAgent", getBodyCellValue: click => click.userAgent, headerCellStyle: {minWidth: "500px"}},
        {name: "Sub_id", key: "subId", getBodyCellValue: click => click.subId,},
        {name: "Source", key: "refererDomain", getBodyCellValue: click => click.refererDomain,},
    ];
    if (statisticType === TRAFFICBACKS) {
        columns.push({name: "Причина ТБ", key: "tbStatus", getBodyCellValue: click => <span title={click.tbDescription}>{click.tbStatus}</span>});
        columns.push({name: "traf_src", key: "trafSrc", getBodyCellValue: click => click.trafSrc});
    }
    return columns;
};

const referralsTableColumns = () => {
    return [
        {name: "Реферал", key: "partnerName", getBodyCellValue: item => item.partnerName, bodyCellStyle: {textAlign: "center"}},
        {name: "Доход с реферала", key: "income", getBodyCellValue: item => item.income, headerCellStyle: {minWidth: "150px", width: "150px"}},
    ];
};

function StatisticDetailsModal(props) {
 const {isLoading, totalRows, rowsLimit, data, isOpen, toggleDetailsModalOpen, statisticType, exportToCsv} = props;
    const [searchValue, setSearchValue] = useState("");
    const [renderedData, setRenderedData] = useState([]);
    const isDownloading = useSelector(state => state.statisticReducer.downloadingDetailedStatistic);

    const searchFields = (statisticType === REFERRALS_AMOUNT)
        ? ["partnerName"]
        : ["ptId", "browser", "dateTime", "deviceName", "ip", "os", "userAgent", "refererDomain", "tbStatus"];
    const filteredData = useDelayedSearch(searchValue, data, searchFields, 300);

    useEffect(() => {
        if (filteredData && filteredData.length >= NUMBER_OF_ROWS_TO_RENDER) {
            setRenderedData(filteredData.slice(0, NUMBER_OF_ROWS_TO_RENDER));
        } else {
            setRenderedData(filteredData);
        }
    }, [filteredData]);

    const handleScroll = (event) => {
        const srcElement = event.target;
        if (srcElement.clientHeight + srcElement.scrollTop < (srcElement.scrollHeight - 200)) {
            return;
        }
        let endIdx = renderedData.length + NUMBER_OF_ROWS_TO_RENDER;
        if (!filteredData[endIdx]) {
            endIdx = filteredData.length;
        }
        setRenderedData(filteredData.slice(0, endIdx));
    };

    const toggle = () => {
        setSearchValue("");
        toggleDetailsModalOpen();
    };

    const label = statisticType === REFERRALS_AMOUNT ? "" : "Детализация " + (statisticType === CLICKS ? "кликов" : "Trafficback");
    const bodyRowKey = (statisticType === REFERRALS_AMOUNT) ? "partnerName" : "externalId";

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle} style={{display: "block", paddingRight: "10px"}}>
            <StatisticDetailsModalHeader label={label} toggle={toggle} searchValue={searchValue} onChangeSearchValue={setSearchValue}/>
            <ModalBody className="sm-mod">
                {data && data.length > 0 && statisticType !== REFERRALS_AMOUNT
                    ? <i className={isDownloading ? "fa fa-spinner fa-pulse fa-3x fa-fw float-right" : "ti ti-import float-right"}
                         style={{fontSize: "20px", marginBottom: "5px", marginRight:"10px", cursor: "pointer"}} onClick={exportToCsv} title="Выгрузить в CSV"/>
                    : null}
                    {isLoading
                        ? <div><Spinner/></div>
                        : (!data || data.length === 0)
                            ? <p className="no-content-msg" style={{fontSize: "14px"}}>Нет данных за выбранный период</p>
                            : <>
                                <div className="responsive-block statistic_table_wrapper" id="fullWidth" onScroll={handleScroll}>
                                      <SimpleTable tableClassName="table  table-bordered nowrap details userselecttext statistic_table sticky-header"
                                                   columns={tableColumns(statisticType)} data={renderedData} bodyRowKey={bodyRowKey}/>
                                </div>
                                {totalRows && totalRows > rowsLimit && <div style={{fontSize: "12px", fontWeight: 600, paddingTop: "10px"}}>
                                    {"Предупреждение: В просмотре/поиске участвуют только последние " + rowsLimit.toLocaleString("ru-RU") +
                                    " записей из " + totalRows.toLocaleString("ru-RU") + ". Для получения всех записей воспользуйтесь выгрузкой в CSV."}
                                </div>}
                            </>
                    }

            </ModalBody>
        </Modal>
    )
}

export default connect(null,
    {
        returnErrors,
    })(StatisticDetailsModal);

const StatisticDetailsModalHeader = ({label, toggle, onChangeSearchValue, searchValue}) => {

    return <div className="modal-header" style={{float: "left", display: "flex"}}>
        <h5 className="modal-title">{label}</h5>
        <div className="input-group d-flex search-line-popup">
            <input className="form-control py-2 border-right-0 border col-lg-12" type="search" value={searchValue}
                   placeholder="Поиск..." id="offer-search" onChange={(e) => onChangeSearchValue(e.target.value)}/>
                <span className="close" onClick={() => onChangeSearchValue("")}>×</span>
        </div>
        <button type="button" className="close" onClick={toggle}>×</button>
    </div>
};