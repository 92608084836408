import {
    CREATE_PROMOTION_LINK,
    PARTNER_OFFER_ITEM_LOADED,
    PARTNER_OFFER_ITEM_LOADING,
    PARTNER_OFFERS_LOADED,
    PARTNER_OFFERS_LOADING,
    PROMOTION_LINK_ENCRYPTED,
    PROMOTION_LINK_ENCRYPTING,
    PROMOTION_LINKS_LOADED,
    PROMOTION_LINKS_LOADING
} from "./actionTypes";
import {getPartnerId} from "../utils/authUtils";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {FOR_ACTIVATION} from "../utils/constants";
import {getSuccess} from "./successActions";
import {searchByString} from "../utils/searchUtils";
import {emptyString} from "../utils/stringUtils";


export const loadPartnerOffers = (type) => (dispatch, getState) => {
    dispatch({type: PARTNER_OFFERS_LOADING, payload: type});

        let partnerId = getPartnerId() ? getPartnerId() : '';
        const sorting = '&sortBy=id&orderDirection=desc';
        axios
            .get(process.env.REACT_APP_API_URL + '/offers/partner?partnerId=' + partnerId + '&offersFilterType=' + type + sorting,
                getConfig(getState))
            .then(res =>
                dispatch({
                    type: PARTNER_OFFERS_LOADED,
                    payload: {offers: res.data, type: type}
                })
            )
            .catch(err => {
                dispatch(returnErrors(err));
            });

};

export const loadPartnerOfferItem = (offerId) => (dispatch, getState) => {
    if (!offerId) {
        return;
    }
    let partnerId = getPartnerId();
    if (!partnerId) {
        return;
    }
    dispatch({type: PARTNER_OFFER_ITEM_LOADING});
    axios
        .get(process.env.REACT_APP_API_URL + '/offers/' + offerId + '/partners/' + partnerId,
            getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_OFFER_ITEM_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};


export const createLinks = (selectedOffers, selectedProject, macros, callback) => (dispatch, getState) => {
    if (!selectedOffers) {
        return;
    }
    let request = [];
    let partnerId = getPartnerId() ? parseInt(getPartnerId()) : null;
    selectedOffers.forEach(offer => request.push({
        offerId: offer.offerId,
        projectId: selectedProject.id,
        partnerId: partnerId,
        macros: macros
    }));

    const success = {success: selectedOffers.length > 1 ? "Ссылки успешно созданы" : "Ссылка успешно создана"};
    let result;
    if (request.length > 0) {
        axios
            .post(process.env.REACT_APP_API_URL + '/projectOffers/activate', request, getConfig(getState))
            .then(res => {
                    result = res.data;
                    dispatch({type: CREATE_PROMOTION_LINK, payload: result});
                }
            )
            .then(() => {
                if (callback) {
                    callback(result)
                }
            })
            .then(() => dispatch(loadPartnerOffers(FOR_ACTIVATION)))
            .then(() => {
                dispatch(getSuccess(success))
            })
            .catch(err => {
                dispatch(returnErrors(err));
            });
    }
};

export const loadPromotionLinks = (offerIds, callbackFunction) => (dispatch, getState) => {
    let partnerId = getPartnerId() ? parseInt(getPartnerId()) : null;
    if (!offerIds || offerIds.length === 0 || !partnerId) {
        return;
    }
    dispatch({type: PROMOTION_LINKS_LOADING});
       const offerIdsParam = offerIds.join(",");
        axios
            .get(process.env.REACT_APP_API_URL + '/promotionLinks?offerIds=' + offerIdsParam + '&partnerId=' + partnerId,
                getConfig(getState))
            .then(res =>
                dispatch({
                    type: PROMOTION_LINKS_LOADED,
                    payload: res.data
                })
            )
            .then(() => {callbackFunction && callbackFunction()})
            .catch(err => {
                dispatch(returnErrors(err));
            });
};

export const testPromotionLink = (linkParams, callbackFunction) => (dispatch, getState) => {
    if (emptyString(linkParams)) {
        return;
    }
    dispatch({type: PROMOTION_LINK_ENCRYPTING});
    axios
        .get(process.env.REACT_APP_API_URL + '/promotionLinks/test/' + linkParams,
            getConfig(getState))
        .then(res =>
            dispatch({
                type: PROMOTION_LINK_ENCRYPTED,
                payload: res.data
            })
        )
        .then(() => {callbackFunction && callbackFunction()})
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const filterByOperators = (offers, operators) => {
    if (!offers || offers.length === 0 || !operators || operators.length === 0) {
        return offers;
    }
    const operatorIds = operators.map(operator => operator.operatorId);
    return offers.filter(offer => operatorIds.includes(offer.operatorId));
};

export const filterByCountries = (offers, countries) => {
    if (!offers || offers.length === 0 || !countries || countries.length === 0) {
        return offers;
    }
    const operatorIds = countries.flatMap(country => country.operatorIds);
    return offers.filter(offer => operatorIds.includes(offer.operatorId));
};

export const filterByStatuses = (offers, statuses) => {
    if (!offers || offers.length === 0 || !statuses || statuses.length === 0) {
        return offers;
    }
    const statusTypes = statuses.map(status => status.status);
    return offers.filter(offer => statusTypes.includes(offer.activityStatus));
};

export const filterByCategories = (offers, categories) => {
    if (!offers || offers.length === 0 || !categories || categories.length === 0) {
        return offers;
    }
    const categoryIds = categories.map(category => category.id);
    return offers.filter(offer => categoryIds.includes(offer.category.id));
};

export const filterByOnlyNew = (offers, isOnlyNew) => {
    if (offers && offers.length !== 0 && isOnlyNew) {
        return offers.filter((offer) => offer.newOffer);
    }
    return offers;
};

export const filterByOnlyRecommended = (offers, isOnlyRecommended) => {
    if (offers && offers.length !== 0 && isOnlyRecommended) {
        return offers.filter((offer) => offer.recommend);
    }
    return offers;
};

export const filterBySearchInput = (offers, inputSearchValue) => {
    return searchByString(offers, ["offerId", "name"], inputSearchValue);
};

export const filterByBillingType = (offers, billingType) => {
    if (!offers || offers.length === 0 || !billingType) {
        return offers;
    }
    return offers.filter(offer => offer.offerIncomes.some(income => income.billingType === billingType.type));
};