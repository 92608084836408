import React, {useEffect, useState} from 'react';
import MaterialUIMultiSelect from "../../../../common/selects/MaterialUIMultiSelect";
import {useSelector} from "react-redux";


export default function SmartLinksOperatorFilter({smartLinks, onSelectOperator, selectedOperators, inputStyle}) {
    const operators = useSelector(state => state.dictionaries.operators);

    const [dropdownValues, setDropdownValues] = useState([]);

    useEffect(() => {
        const getFilteredOperators = () => {
            let resultOperators = [];
            if (operators && smartLinks) {
                operators.forEach(function (operator) {
                    const found = smartLinks.find(function (smartLink) {
                        return smartLink.operators.includes(operator.operatorType);
                    });
                    if (found) {
                        resultOperators.push(operator);
                    }
                })
            }
            return resultOperators;
        };
       setDropdownValues(getFilteredOperators());
    }, [smartLinks, operators, selectedOperators]);

    return (
        <MaterialUIMultiSelect styles={inputStyle} dropdownData={dropdownValues} values={selectedOperators} dataField='operatorType'
                               displayedField='name' label='Операторы' placeholder='-'
                               onSelect={onSelectOperator}/>
    )
}


