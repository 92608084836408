import React, {useEffect, useState} from "react";
import userImg from "../../../resources/images/user.png";
import notificationImg from "../../../resources/images/notification.png";
import supportImg from "../../../resources/images/support.png";
import exitImg from "../../../resources/images/exit.png";
import walletImg from "../../../resources/images/wallet.png";
import HeaderDesktopInfoItem from "../desktop/HeaderDesktopInfoItem";
import HeaderDesktopActionItem from "../desktop/HeaderDesktopActionItem";
import NavigationMenuMobilePopupButton from "../mobile/NavigationMenuMobilePopupButton";
import MobileNavigationMenu from "../mobile/MobileNavigationMenu";
import {isScreenWidthLessThen} from "../../../utils/displayUtils";
import {
    URL_PAYMENTS,
    URL_PROFILE,
    URL_ROOT,
    URL_STATISTIC
} from "../../../properties";
import {pathParameter} from "../../../utils/urlUtils";
import {useHistory} from 'react-router-dom';
import {connect, useSelector} from "react-redux";
import {logout} from "../../../actions/authActions";
import {executeSync} from "../../../utils/syncUtils";
import {loadPartnerBalance} from "../../../actions/partnerBalanceActions";
import {getMoneyString} from "../../../utils/formatUtils";
import {getFromLocalStorage, setToLocalStorage} from "../../../utils/localStorageUtils";
import {DARK_THEME, DARK_THEME_CLASS_NAME, LIGHT_THEME, LOCAL_STORAGE_THEME} from "../../../utils/constants";
import {loadChatHistory, toggleOpenChat} from "../../../actions/chatActions";
import {loadHeaderNews, readNewsItem} from "../../../actions/newsActions";
import HeaderNewsItemsPopup from "./news/HeaderNewsItemsPopup";
import "@theme-toggles/react/css/Classic.css";
import {Classic} from "@theme-toggles/react";

const STORAGE_PERIOD_IN_HOURS = 365 * 24;
const MAX_NEWS_PER_LOAD = 7;

function PageHeader({
                        logout, loadPartnerBalance, loadHeaderNews, partnerBalance,
                        getLinksData, numberOfUnreadMessages, toggleOpenChat, loadChatHistory,
                        isChatOpened, news, readNewsItem
                    }) {
    const auth = useSelector(state => state.auth);
    const {isAdminAsPartner, isPartner} = auth;

    const history = useHistory();
    const [themeType, setThemeType] = useState(null);
    const [isMobilePopupOpen, setIsMobilePopupOpen] = useState(false);
    const [isNewsPopupOpen, setIsNewsPopupOpen] = useState(false);
    const isPartnerPage = isAdminAsPartner || isPartner;
    const isMobileVersion = isScreenWidthLessThen(891);

    useEffect(() => {
        let savedTheme = getFromLocalStorage(LOCAL_STORAGE_THEME);
        changeTheme(!savedTheme ? LIGHT_THEME : savedTheme.value);
        loadHeaderNews(MAX_NEWS_PER_LOAD, null);
    }, []);

    useEffect(() => {
        if (isPartnerPage) {
            loadPartnerBalance();
        }
    }, [isPartnerPage]);

    const redirectToFin = () => {
        const redirectUrl = isAdminAsPartner
            ? URL_PAYMENTS + "?partnerId=" + pathParameter("partnerId")
            : URL_PAYMENTS;
        history.push(redirectUrl);
    };

    const handleLogout = () => {
        executeSync(() => logout())
            .then(() => history.push(URL_ROOT));
    };

    const redirectToProfile = () => {
        const partnerId = pathParameter("partnerId");
        const redirectUrl = URL_PROFILE + (partnerId ? "?partnerId=" + pathParameter("partnerId") : "");
        history.push(redirectUrl);
    };

    const toggleTheme = () => {
        changeTheme(themeType === LIGHT_THEME ? DARK_THEME : LIGHT_THEME);
    };

    const changeTheme = (theme) => {
        const storageTheme = {value: theme};
        setToLocalStorage(LOCAL_STORAGE_THEME, storageTheme, STORAGE_PERIOD_IN_HOURS);
        setThemeType(theme);
        let bodyEl = document.body;
        if (theme === LIGHT_THEME) {
            bodyEl.classList.remove(DARK_THEME_CLASS_NAME);
        } else {
            bodyEl.classList.add(DARK_THEME_CLASS_NAME);
        }
    };

    const togglePopupOpen = () => {
        setIsMobilePopupOpen(!isMobilePopupOpen);
        setIsNewsPopupOpen(false);
    };

    const toggleChat = () => {
        !isChatOpened && loadChatHistory();
        toggleOpenChat(!isChatOpened);
    };

    const toggleOpenNewsPopup = () => {
        setIsNewsPopupOpen(!isNewsPopupOpen);
    };

    const readItem = (newsItem) => {
        readNewsItem(newsItem, () => loadHeaderNews(news.length, null));
    };

    const loadMoreNews = () => {
        loadHeaderNews(MAX_NEWS_PER_LOAD, news?.length > 0 ? news[news.length - 1].id : null);
    };

    const balance = partnerBalance ? getMoneyString(partnerBalance.balance) + "р" : "0р";
    const partnerName = isPartnerPage && partnerBalance ? partnerBalance.email + " | " + partnerBalance.dominicPartnerId : getFromLocalStorage("user")?.login;
    const notReadItems = news && news.filter(item => item.isNew);
    const notReadItemsAmount = notReadItems ? notReadItems.length : 0;

    const renderMobileNavigationMenu = () => {
        return <MobileNavigationMenu
            linksData={getLinksData}
            isOpen={isMobilePopupOpen}
            onClose={togglePopupOpen}
            onClickLogoutButton={handleLogout}
            onClickThemeButton={toggleTheme}
            onClickChatButton={toggleChat}
            partnerName={partnerName}
            isPartnerPage={isPartnerPage}
            notReadItemsAmount={notReadItemsAmount}
            toggleOpenNewsPopup={toggleOpenNewsPopup}
        />
    };

    const renderBalanceIcon = () => {
        return <HeaderDesktopInfoItem className="balance" img={walletImg} title={balance} onClick={redirectToFin}/>
    };

    const renderProfileIcon = () => {
        return <HeaderDesktopInfoItem className="mail" img={userImg} title={partnerName} onClick={redirectToProfile}/>
    };

    const renderNotificationsIcon = () => {
        return <HeaderDesktopActionItem img={notificationImg} imgClassName="notification-icon"
                                        onClick={toggleOpenNewsPopup}
                                        additionalEl={notReadItemsAmount > 0 && <div className="notyDot"/>}/>
    };

    const renderChatIcon = () => {
        return <HeaderDesktopActionItem img={supportImg} onClick={toggleChat}
                                        additionalEl={numberOfUnreadMessages && <div className="notyDot"/>}/>
    };

    const renderThemeIcon = () => {
        return <div className="nav-footer-icon">
            <Classic reversed duration={750} onToggle={toggleTheme}/>
        </div>
    };

    const renderLogoutIcon = () => {
        return <HeaderDesktopActionItem img={exitImg} onClick={handleLogout}/>
    };

    return (
        <div className="header" id="page-header">
            {isMobileVersion && renderMobileNavigationMenu()}
            <div className="info">
                {isPartnerPage && renderBalanceIcon()}
                {renderProfileIcon()}
            </div>

            <div className="nav-footer">
                {(!isMobilePopupOpen || !isMobileVersion) &&
                <div className="row nowrap">
                    {renderNotificationsIcon()}
                    {isPartnerPage && renderChatIcon()}
                </div>
                }
                {(isMobilePopupOpen || !isMobileVersion) &&
                <div className="row nowrap">
                    {renderThemeIcon()}
                    {renderLogoutIcon()}
                </div>
                }
            </div>

            <NavigationMenuMobilePopupButton onClick={togglePopupOpen}/>

            {news && isNewsPopupOpen && (
                <HeaderNewsItemsPopup
                    news={news}
                    readNewsItem={readItem}
                    notReadItemsAmount={notReadItemsAmount}
                    loadMoreNews={loadMoreNews}
                    toggleOpenNewsPopup={toggleOpenNewsPopup}
                />
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    partnerBalance: state.partnerBalanceReducer.balance,
    auth: state.auth,
    isChatOpened: state.chatReducer.isChatOpened,
    newsLoading: state.newsReducer.isLoading,
    news: state.newsReducer.headerNews,
});

export default connect(mapStateToProps, {
    logout,
    loadPartnerBalance,
    toggleOpenChat,
    loadChatHistory,
    loadHeaderNews,
    readNewsItem,
})(PageHeader);
