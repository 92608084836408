import React, {Fragment} from "react";
import HtmlEditor from "./HtmlEditor";
import {useSelector} from "react-redux";

const options = {
    showPathLabel: true,
    charCounter: true,
    height: "100% !important",
    resizingBar: true,
    buttonList: [
        ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
        ['link', 'fullScreen', 'showBlocks', 'codeView']
    ],
};

export default (props) => {

    const {value, handleChangeContent, handleSave, isEditorOpened, toggleEditorOpened} = props;
    const {isAdminAsPartner, isPartner} = useSelector(state => state.auth);
    const isLoggedAsPartner = isPartner || isAdminAsPartner;

    const buttons = () => {
        return (
            !isLoggedAsPartner
            &&
            <div className="circle-btn-group" style={{justifyContent: "flex-start"}}>
                <button className={isEditorOpened ? "circle-btn selected" : "circle-btn"} title="Редактировать"
                        onClick={toggleEditorOpened}>
                    <i className="ti ti-marker-alt"/>
                </button>
                {handleSave
                &&
                <button className="circle-btn" title="Сохранить изменения" onClick={handleSave}>
                    <i className="ti ti-save"/>
                </button>}
            </div>
        )
    };

    const content = () => {
        return (
            <div className="tab-pane fade active show pl-3" id="custom-nav-home" role="tabpanel"
                 aria-labelledby="custom-nav-home-tab">
                {
                    isLoggedAsPartner || !isEditorOpened
                        ? <div dangerouslySetInnerHTML={{__html: value}}/>
                        : <Fragment>
                            <HtmlEditor onChange={handleChangeContent} initialContent={value} options={options}/>
                            <br/>
                        </Fragment>
                }
            </div>
        )
    };

    return (
        <Fragment>
            {buttons()}
            {content()}
        </Fragment>
    )
}