import React from "react";
import useResizeObserver from "../../hooks/useResizeObserver";
import {useSelector} from "react-redux";


export default function FilteredContentWrapper({children}) {
    const layoutMargins = 30;
    const isFilterBlockOpenByBtn = useSelector(state => state.navigationReducer.isFilterBlockOpenByBtn);

    let width = useResizeObserver(document.getElementById("contentHeader"))?.fullWidth - layoutMargins + "px";
    width = !isFilterBlockOpenByBtn && width;

    return (
        <div style={{height: "inherit", minWidth: width, width: width}}>
            {children}
        </div>
    );
}
