import {
    DICTIONARY_ACTIVE_OPERATORS_LOADED,
    DICTIONARY_CATEGORIES_LOADED,
    DICTIONARY_CATEGORIES_LOADING, DICTIONARY_GROUPED_OPERATORS_LOADED, DICTIONARY_GROUPED_OPERATORS_LOADING,
    DICTIONARY_OPERATOR_TYPES_LOADED,
    DICTIONARY_OPERATOR_TYPES_LOADING,
} from "../../actions/actionTypes";
import {AZ, BEELINE, BY, KZ, MEGAFON, MTS, RU, TELE_2, UA, UZ, TJ} from "../../utils/constants";


const operatorTypes = [BEELINE, MTS, MEGAFON, TELE_2];
const countryTypes = [AZ, BY, KZ, RU, UA, UZ, TJ];

const initialState = {
    billingTypes: [],
    operatorsAndCountries: [],
    operators: [],
    groupedOperators: [],
    countries: [],
    categories: [],
    activeOperators: [],
    activeCountries: [],
    loading: false
};



export default function (state = initialState, action = {}) {
    switch (action.type) {
        case DICTIONARY_OPERATOR_TYPES_LOADING:
        case DICTIONARY_GROUPED_OPERATORS_LOADING:
        case DICTIONARY_CATEGORIES_LOADING:
            return {
                ...state,
                loading: true
            };
        case DICTIONARY_OPERATOR_TYPES_LOADED:
            return {
                ...state,
                operatorsAndCountries: action.payload ? action.payload.filter(type => operatorTypes.includes(type.operatorType)
                    || countryTypes.includes(type.operatorType)) : state.operators,
                operators: action.payload ? action.payload.filter(type => operatorTypes.includes(type.operatorType)) : state.operators,
                countries: action.payload ? action.payload.filter(type => countryTypes.includes(type.operatorType)) : state.countries,
                loading: false
            };
        case DICTIONARY_CATEGORIES_LOADED:
            return {
                ...state,
                categories: action.payload,
            };
        case DICTIONARY_GROUPED_OPERATORS_LOADED:
            return {
                ...state,
                groupedOperators: formatGroupedOperators(action.payload),
            };
        case DICTIONARY_ACTIVE_OPERATORS_LOADED:
            return {
                ...state,
                activeOperators: action.payload,
                activeCountries: action.payload ? formatActiveCountries(action.payload) : state.activeCountries
            };
        default:
            return state;
    }

}

const formatActiveCountries = (operators) => {
    return operators.reduce((r, a) => {
        const existed = r.find(item => item.countryId === a.countryId);
        if (existed) {
            existed.operatorIds.push(a.operatorId);
        } else {
            const newItem = {
                countryId: a.countryId,
                countryName: a.countryName,
                operatorIds: [a.operatorId]
            };
            r.push(newItem);
        }
        return r;
    }, [])
};


const formatGroupedOperators = (groupedOperators) => {
    const result = [];
    groupedOperators.forEach(group => {
        result.push(
            {
                label: group.name, groupValue: group.name, value: group.operatorIds,
                options: group.nestedOperators && group.nestedOperators.map(operator => {
                    return {label: operator.name, value: operator.operatorIds,  groupValue: group.name}
                })
            });
    });
    return result;
};