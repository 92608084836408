import React, {useEffect, useRef} from "react";
import {
    getOperatorImg
} from "../../../../utils/operatorsUtil";
import SimpleTable from "../../../common/tables/SimpleTable";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import {replaceDomain} from "../../../../utils/urlUtils";
import {isMobileScreen} from "../../../../utils/displayUtils";

export default function SmartLinksCreationTable(props) {
    const {smartLinks, onDeleteSmartLink, onShowEditSmartLinkComponent, selectedDomain, shouldScrollToSmartLink} = props;
    const childRef = useRef();
    const smartLinkTableRef = useRef();

    useEffect(() => {
        if (shouldScrollToSmartLink && smartLinks && isMobileScreen()) {
            smartLinkTableRef.current.scrollTo({left: 1000, behavior: 'smooth'});
            window.scrollTo({top: 230, behavior: 'smooth'});
        }

    }, [smartLinks, smartLinkTableRef]);



    function deleteSmartLink(smartLink) {
        onDeleteSmartLink(smartLink);
    }

    function editSmartLink(smartLink) {
        if (!smartLink || !smartLink.id) {
            return;
        }
        onShowEditSmartLinkComponent(smartLink.id);
    }

    const tableColumns = () => {
        return [
            {
                name: "Название смартлинка",
                key: "name",
                getBodyCellValue: smartLink => smartLink.id ? (smartLink.id + " | " + smartLink.name) : smartLink.name,
                headerCellStyle: {minWidth: "300px", width: "300px"}
            },
            {
                name: "Тематика",
                key: "theme",
                getBodyCellValue: smartLink => smartLink.category.name,
                headerCellStyle: {minWidth: "110px", width: "110px"}
            },
            {
                name: "ОСС",
                key: "oss",
                getBodyCellValue: smartLink => smartLink.operators.map(operatorType =>
                    <img key={smartLink.name + operatorType} src={getOperatorImg(operatorType)} alt=""/>),
                headerCellStyle: {minWidth: "150px", width: "150px"},
                bodyCellClassName: "td-operator inline"
            },
            {
                name: "Количество офферов",
                key: "amount",
                getBodyCellValue: smartLink => smartLink.offerAmount,
                headerCellStyle: {minWidth: "90px", width: "90px"},
            },
            {
                name: "Ссылка",
                key: "link",
                getBodyCellValue: smartLink => <span className="link" onClick={() => childRef.current.handleClickCopy(getLink(smartLink.link, selectedDomain))}>
                        <input readOnly={true}  type="text" placeholder="ссылка появится после нажатия на 'Получить ссылки'"
                               className="linkToCopy copyClick form-control form-control-sm"
                               value={getLink(smartLink.link, selectedDomain)}/></span>,
            },
            {
                name: "",
                key: "buttons",
                getBodyCellValue: smartLink => <>
                    {smartLink.id ?
                        <i className="ti ti-pencil cursor"
                           onClick={() => editSmartLink(smartLink)}/>
                        : null}
                    <i className="ti ti-close cursor"
                       onClick={() => deleteSmartLink(smartLink)}/>
                </>,
                headerCellStyle: {minWidth: "60px", width: "60px"}
            },
        ]
    };

    return (
        <div className="col-12">
            <p className="title-smart">Смартлинки</p>
            <div className="responsive-block" id="fullWidth" style={{overflowX: 'auto', overflowY: 'hidden'}} ref={smartLinkTableRef}>
                    <SimpleTable columns={tableColumns()} data={smartLinks} bodyRowKey="id"/>
            </div>
            <LinkCopyPopup ref={childRef}/>
        </div>

    )
}

const getLink = (link, selectedDomain) => {
    if (!selectedDomain || selectedDomain.cname === "") {
        return link;
    }
    return replaceDomain(link, selectedDomain && selectedDomain.cname)
};

