import React, {Fragment, useRef} from "react";

export default function IpRangeUploadXlsxButton({uploadIpRanges}) {

    const inputFileRef = useRef();

    const onSelectFile = () => {
        inputFileRef.current.click();
    };

    const onChangeFile = (e) => {
        uploadIpRanges(e.target.files[0]);
        e.target.value = "";
    };

    return (
        <Fragment>
            <button className="circle-btn" title="Загрузить диапазоны" onClick={onSelectFile}>
                <i className="ti ti-upload"/>
            </button>
            <input type="file" style={{display: "none"}} ref={inputFileRef} onChange={onChangeFile} onSelect={onChangeFile}/>
        </Fragment>
    )
}