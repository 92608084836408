import React from "react";
import MaterialUIMultiSelect from "../../../common/selects/MaterialUIMultiSelect";

export default function OffersFilter({offers, selectedOffers, handleChangeFilterData, inputStyle}) {

    const onSelect = (value) => {
        handleChangeFilterData("offers", value, true);
    };

    return (
            <MaterialUIMultiSelect dropdownData={offers}
                                   values={selectedOffers}
                                   label={'Офферы'}
                                   placeholder={'-'}
                                   onSelect={onSelect}
                                   isSearchable={true}
                                   styles={inputStyle}
            />
    );
}