import {
    SUBSCRIPTION_SERVICES_LOADED,
    SUBSCRIPTION_SERVICES_LOADING
} from "./actionTypes";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnErrors} from "./errorActions";

export const loadSubscriptionServices = () => (dispatch, getState) => {

    dispatch({type: SUBSCRIPTION_SERVICES_LOADING});

    axios
        .get(process.env.REACT_APP_API_URL + '/subscriptionServices', getConfig(getState))
        .then(res =>
            dispatch({
                type: SUBSCRIPTION_SERVICES_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });

};