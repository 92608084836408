import React, {useEffect, useRef} from "react";
import {getBillingTypeName} from "../../../../utils/offerUtils";
import SimpleTable from "../../../common/tables/SimpleTable";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import OperatorImage from "../../../common/images/OperatorImage";
import {replaceDomain} from "../../../../utils/urlUtils";
import useElementCoordinates from "../../../../hooks/useElementCoordinates";
import {isMobileScreen} from "../../../../utils/displayUtils";


export default function LinksCreationTable(props) {
    const {offers, selectedProject, excludeOffer, selectedDomain, shouldScrollToLink, promotionLinks} = props;
    const childRef = useRef();
    const tableRef = useRef();
    const tableCoordinates = useElementCoordinates(tableRef.current);

    useEffect(() => {
        if (shouldScrollToLink && tableRef.current && isMobileScreen()) {
            tableRef.current.scrollTo({left: 1000, behavior: 'smooth'});
            const top = tableCoordinates.top - 100;
            window.scrollTo({top: top, behavior: 'smooth'});
        }
    }, [shouldScrollToLink, tableRef]);


   const tableColumns = () => {
        return [
            {
                name: "Название оффера",
                key: "name",
                getBodyCellValue: offer => offer.offerId + ' | ' + offer.name,
                headerCellStyle: {minWidth: "300px"}
            },
            {
                name: "Тематика",
                key: "theme",
                getBodyCellValue: offer => offer.category.name,
                headerCellStyle: {minWidth: "110px", width: "110px"}
            },
            {
                name: "ОСС",
                key: "oss",
                getBodyCellValue: offer => <OperatorImage type={offer.operatorType}/>,
                headerCellStyle: {minWidth: "150px", width: "150px"},
                bodyCellClassName: "td-operator"
            },
            {
                name: "Выплата",
                key: "payment",
                getBodyCellValue: offer => offer.offerIncomes.map(income => <div key={income.offerIncomeId} style={{marginTop: '5px'}}>
                    <span>{income.income}</span><br/><span style={{"fontSize": "10px"}}>
                              {getBillingTypeName(income.billingType, false)}</span>
                </div>),
                headerCellStyle: {minWidth: "90px", width: "90px"},
            },
            {
                name: "Ссылка",
                key: "link",
                getBodyCellValue: offer => offer.offerIncomes.map(income => <>
                <span className="link"
                      onClick={() => childRef.current.handleClickCopy(getPromotionLink(selectedProject, income, selectedDomain, promotionLinks))}>
                        <input style={{marginTop: '5px'}} readOnly={true}  type="text" placeholder="ссылка появится после нажатия на 'Получить ссылки'"
                               className="linkToCopy copyClick form-control form-control-sm"
                               value={getPromotionLink(selectedProject, income, selectedDomain, promotionLinks)}/></span>
                </>),
                headerCellStyle: {minWidth: "330px"},
            },
            {
                name: "",
                key: "buttons",
                getBodyCellValue: offer => <i onClick={() => excludeOffer(offer)} className="ti ti-close cursor"/>,
                headerCellStyle: {minWidth: "40px", width: "40px"}
            },
        ]
    };

    return (
        <div className="col-12">
            <p className="title-smart">Офферы</p>
            <div className="responsive-block" id="fullWidth" style={{overflowX: 'auto', overflowY: 'hidden'}} ref={tableRef}>
                <SimpleTable columns={tableColumns()} data={offers} bodyRowKey="offerId"/>
            </div>
            <LinkCopyPopup ref={childRef}/>
        </div>
    )
}


function getPromotionLink(selectedProject, offer, selectedDomain, promotionLinks) {
    if (!selectedProject || !promotionLinks) {
        return "";
    }
    const link = promotionLinks.filter(link => link.offerId === offer.offerId).find(promotionLink => promotionLink.sourceId === selectedProject.sourceId);

    return link ? replaceDomain(link.link, selectedDomain?.cname) : '';
}
