import React from "react";
import SearchInput from "../SearchInupt";
import XlsxButton from "../../common/buttons/XlsxButton";
import TableSettingsButton from "../../common/buttons/TableSettingsButton";
import ToggleFiltersButton from "../../common/buttons/ToggleFiltersButton";
import IpRangesButton from "../../common/buttons/IpRangesButton";
import SmallNavTabs from "../navTabs/SmallNavTabs";


export default function ContentHeader({tabs, selectedTabName, onSelectTab, additionalElements, navRightButtons, displaySearchInput, displayXlsxBtn,
                                          displayTableSettingsBtn, displayFiltersBtn, displayIpRangesBtn, onChangeSearchInput, searchInputValue,
                                          searchInputAmount, beforeSearchInputAmount, searchInputLabel, onClickTableSettingsBtn, isTableSettingsExpanded, xlsxData}) {

    const displayFilterBtns = additionalElements || displayXlsxBtn || displayIpRangesBtn || displayTableSettingsBtn || displayFiltersBtn;
    const displayBlockFilters = displaySearchInput || displayFilterBtns;

    return (
        <div className="card-header open-menu" id="contentHeader" key={selectedTabName}>
            {navRightButtons}
            {<SmallNavTabs tabs={tabs} selectedTabName={selectedTabName} onSelectTab={onSelectTab}/>}
            {displayBlockFilters && <div className="block-filters">
                {displaySearchInput && <SearchInput amount={searchInputAmount} allAmount={beforeSearchInputAmount} label={searchInputLabel} onTextTyping={onChangeSearchInput} searchValue={searchInputValue}/>}
                {displayFilterBtns && <div className="filter-buttons">
                    {additionalElements}
                    {displayIpRangesBtn && <IpRangesButton />}
                    {displayXlsxBtn && <XlsxButton xlsxData={xlsxData}/>}
                    {displayTableSettingsBtn && !isTableSettingsExpanded && <TableSettingsButton className={isTableSettingsExpanded ? "active" : ""}
                        onClick={onClickTableSettingsBtn}
                    />}
                    {displayTableSettingsBtn && isTableSettingsExpanded && <TableSettingsButton className={isTableSettingsExpanded ? "active" : ""}
                        onClick={onClickTableSettingsBtn}
                    />}
                    {displayFiltersBtn && <ToggleFiltersButton/>}


                </div>}
            </div>
            }


        </div>
    );
}
