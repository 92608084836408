import React, {Component} from "react";
import {connect} from "react-redux";
import BasePage from "../BasePage";
import OfferEditMainData from "./offerEditing/OfferEditMainData";
import OfferEditIncomesData from "./offerEditing/OfferEditIncomesData";
import {
    activate,
    deactivate,
    deprecate,
    deleteOffer,
    saveOffer,
    loadOffer,
    loadChildren, loadAllManagerOffers
} from "../../../actions/managerOfferActions";
import {loadCategories} from "../../../actions/dictionaryActions";
import {loadSubscriptionServices} from "../../../actions/subscriptionServiceActions";
import { isNumberStringWithTwoDecimalPlaces} from "../../../utils/validationUtils";
import ActionPopover from "../../common/popovers/ActionPopover";
import {loadPartnersFilterData} from "../../../actions/managerPartnersActions";
import {
    deleteIncome,
    loadOfferIncomes,
    loadWithChildrenOfferIncomes,
} from "../../../actions/incomesActions";
import {showWarning} from "../../../actions/warningActions";
import {getIsoLocalString, getServerCurrentDay} from "../../../utils/dateUtils";
import {URL_OFFER_EDIT, URL_OFFERS_MANAGER} from "../../../properties";
import {DESC, OFFER_EDIT_MAIN, OFFER_EDIT_INCOMES, PERCENT} from "../../../utils/constants";
import {addRetentionEvent} from "../../../actions/retentionEventActions";
import {loadActualCurrencyRates} from "../../../actions/currencyRateActions";
import {offerEditTabs, offerEditTabsWithoutIncomes} from "../../../utils/pageTabsUtils";
import PageContent from "../../common/page/PageContent";
import {NEW_OFFER_IDS, newOfferId, offerNamePostfixMap} from "../../../utils/offerUtils";
import {executeSync} from "../../../utils/syncUtils";


const fieldFormatters = {
    name: (value) => !value ? '' : value,
    description: (value) => !value ? '' : value,
    comment: (value) => !value ? '' : value,
    eup: (value) => !value ? '' : value.replace(",", "."),
    term: (value) => !value ? '' : value,
    urlTemplate: (value) => !value ? '' : value,
    billingType: (value) => value,
    category: (value) => value,
    subscriptionService: (value) => value,
    operatorType: (value) => value,
    incomeDateMin: value => getIsoLocalString(value),
};

const fieldValidationPredicates = {
    name: () => true,
    description: () => true,
    comment: () => true,
    eup: (value) => isNumberStringWithTwoDecimalPlaces(value),
    term: () => true,
    urlTemplate: () => true,
    billingType: () => true,
    category: () => true,
    subscriptionService: () => true,
    operatorType: () => true,
};


class OfferEditPage extends Component {

    state = {
        selectedTab: "OFFER_EDIT_MAIN",
        offer: null,
        children: null,
        filteredIncomes: [],
        offerHasChanged: false,
        errorFields: [],
        uploadedImgFile: null,
        uploadedImgPreview: null,
        sortableIncomeColumn: 'fromDate',
        sortableIncomeDirection: DESC,
        footerSearchValue: "",
    };

    componentDidMount() {
        let offerId = window.location.pathname;
        offerId = offerId.split("/offers/edit/");
        offerId = offerId && offerId.length > 0 && offerId[1];
        this.props.loadOffer(offerId);
        this.props.loadChildren(offerId);
        this.props.loadCategories();
        this.props.loadSubscriptionServices();
        this.props.loadPartnersFilterData();
        this.props.loadActualCurrencyRates();
        this.props.loadAllManagerOffers();
        this.props.loadWithChildrenOfferIncomes([offerId]);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let newState = null;
        if (this.props.offerCardData !== prevProps.offerCardData) {
            newState = {...this.state,
                offer: Object.assign({}, this.props.offerCardData),
            };
        }
        if (this.props.children && this.props.children !== prevProps.children) {
            newState = {...(newState ? newState : this.state), children: this.props.children}
        }
        if (newState) {
            this.setState(newState);
        }
        if (this.state.offerHasChanged && this.state.offerHasChanged !== prevState.offerHasChanged) {
            this.addHasChangesRetentionEvent();
        }

    }

    selectTab = (tabName) => {
        this.setState({
            selectedTab: tabName
        })
    };

    toggleCheckboxSwitch = (fieldName) => {
        const offer = this.state.offer;
        offer[fieldName] = !offer[fieldName];
        this.setState({...this.state, offer: offer, offerHasChanged: true});
    };

    handleInputChange = (fieldName, value) => {
        let {offer, errorFields} = this.state;
        value = fieldFormatters[fieldName](value);
        offer[fieldName] = value;
        let children = this.state.children;
        if (fieldName === "name" && children?.length > 0) {
            children = children.map(child => {return {...child, offerName: offerNamePostfixMap[child.billingType](offer.name)}});
        }
        if (!fieldValidationPredicates[fieldName](value)) {
            if (!errorFields.includes(fieldName)) {
                errorFields = errorFields.slice(0);
                errorFields.push(fieldName);
            }
        } else {
            errorFields = errorFields.filter(field => field !== fieldName);
        }
        this.setState({...this.state, offer: {...offer},  children: children, errorFields: errorFields, offerHasChanged: true});
    };

    onSelectIncomeDateMin = (date) => {
        let {offer, errorFields} = this.state;
        offer.incomeDateMin = fieldFormatters["incomeDateMin"](date);
        this.setState({...this.state, offer: offer, errorFields: errorFields, offerHasChanged: true});
    };

    excludeChildFromClone = (childId) => {
        let children = this.state.children.filter(child => child.id !== childId);
        this.setState({
            ...this.state,
            children: children
        })
    };

    addChildToClone = (child) => {
        let children = this.state.children ? this.state.children?.filter(child => child.id !== child.id) : [];
        children.push(child);
        this.setState({
            ...this.state,
            children: children
        })
    };



    saveOffer = (callback) => {
        const {offer, offerHasChanged, errorFields} = this.state;
        if (!offerHasChanged || errorFields.length > 0) {
            return;
        }
        const body = {
            id: NEW_OFFER_IDS.includes(offer.offerId) ? "" : offer.offerId,
            billingType: offer.billingType ? offer.billingType : "",
            categoryId: offer.category ? offer.category.id : "",
            subscriptionServiceId: offer.subscriptionService ? offer.subscriptionService.id : "",
            name: offer.name ? offer.name : "",
            description: offer.description ? offer.description : "",
            comment: offer.comment ? offer.comment : "",
            eup: offer.eup,
            incomeDateMinIsoString: offer.incomeDateMin ? offer.incomeDateMin : "",
            term: offer.term,
            urlTemplate: offer.urlTemplate ? offer.urlTemplate : "",
            hiddenFromNovices: offer.hidden,
            recommend: offer.recommend,
            partnerClass: offer.partnerClass,
            childrenJsonString: this.state.children && this.state.children.length > 0
                ? JSON.stringify(this.state.children.map(child => {return {...child, id: (NEW_OFFER_IDS.includes(child.id) ? null : child.id)}}))
                : "",
        };
        if (this.state.uploadedImgFile) {
            body.offerImg = this.state.uploadedImgFile;
        }
        const needToRedirectAfterSave = !NEW_OFFER_IDS.includes(offer.offerId);
        this.props.saveOffer(body, this.state.uploadedImgFile, (offer) => callback ? callback(offer) : needToRedirectAfterSave ? this.redirectToOffers() : null)
    };


    activate = () => {
        this.props.activate(this.state.offer.offerId, true, () => this.afterActivateOrDeactivateCallback());
    };

    deactivate = () => {
        this.props.deactivate(this.state.offer.offerId, true, () => this.afterActivateOrDeactivateCallback());
    };

    deprecate = (deprecatedDate) => {
        this.props.deprecate(this.state.offer.offerId, deprecatedDate);
    };

    deleteOffer = () => {
        this.props.deleteOffer(this.state.offer.offerId, () => this.redirectToOffers())
    };

    afterActivateOrDeactivateCallback = () => {
        let offerId = window.location.pathname;
        offerId = offerId.split("/offers/edit/");
        offerId = offerId && offerId.length > 0 && offerId[1];
        this.props.loadOffer(offerId);
        this.props.loadChildren(offerId);
    };

    cloneOffer = () => {

        let offer = Object.assign({}, this.state.offer);
        offer.offerId = newOfferId(offer.billingType);
        offer.name = "копия_" + offer.name;
        if (offer.status === "DELETED" || offer.status === "DEPRECATED") {
            offer.status = "INACTIVE";
        }
        this.cloneOfferImg(offer);
        const children = this.cloneChildren();
        this.setState({
            ...this.state,
            offer: offer,
            children: children,
            offerHasChanged: true
        });
    };

    cloneChildren = () => {
        let children = this.state.children;
        if (!children || children.length === 0) {
            return;
        }
        children = Object.assign([], children);
        return children.map(child => this.cloneChild(child));
    };

    cloneChild = (child) => {
        child = Object.assign({}, child);
        child.id = newOfferId(child.billingType);
        return child;
    };


    cloneOfferImg = (offer) => {
        fetch(process.env.REACT_APP_CORS_PROXY_URL + offer.imgUrl)
            .then(res => res.blob())
            .then(blob => {
                const splitted = offer.imgUrl.split(".");
                if (splitted.length > 0) {
                    const offerImg = new File([blob], splitted[splitted.length - 1]);
                    this.setState({
                        ...this.state,
                        uploadedImgFile: offerImg
                    })
                }
            });
    };

    selectOfferImg = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                ...this.state,
                uploadedImgFile: file,
                uploadedImgPreview: reader.result,
                offerHasChanged: true
            });
        };
        reader.readAsDataURL(file);
    };

    redirectToOffers = () => {
        executeSync(() => window.history.pushState({urlPath: URL_OFFERS_MANAGER}, "", URL_OFFERS_MANAGER)).then(() => window.location.reload());
    };


    setIncomeSorting = (fieldName, direction) => {
        this.setState({
            ...this.state,
            sortableIncomeColumn: fieldName,
            sortableIncomeDirection: direction,
        })
    };

    changeSearchValue = (value) => {
        this.setState({
            ...this.state,
            footerSearchValue: value,
        })
    };

    setFilteredIncomesAmount = (value) => {
        this.setState({
            ...this.state,
            filteredIncomesAmount: value,
        })
    };

    addHasChangesRetentionEvent = () => {
        this.props.addRetentionEvent((retentionEventCallback) => this.props.showWarning("Имеются несохраненные данные. Сохранить?",
            () => this.saveOffer(retentionEventCallback),
            () => retentionEventCallback()));
    };

    afterCreateOfferCallback = (offer) => {
        const newUrl = URL_OFFER_EDIT + "/" + offer.offerId;
        executeSync(() => window.history.pushState({urlPath: newUrl}, "", newUrl)).then(() => window.location.reload());
    };

    renderContentEl = () => {
        const {selectedTab, offer, children} = this.state;
        switch (selectedTab) {
            case OFFER_EDIT_MAIN:
                return <OfferEditMainData offer={offer}
                                          children={children}
                                          excludeChildFromClone={this.excludeChildFromClone}
                                          addChildToClone={this.addChildToClone}
                                          toggleCheckboxSwitch={this.toggleCheckboxSwitch}
                                          handleInputChange={this.handleInputChange}
                                          onSelectIncomeDateMin={this.onSelectIncomeDateMin}
                                          errorFields={this.state.errorFields}
                                          uploadedImg={this.state.uploadedImgPreview}
                                          selectOfferImg={this.selectOfferImg}
                                          buttons={this.renderButtons()}
                                          />;
            case OFFER_EDIT_INCOMES:
                return <OfferEditIncomesData
                    pageState={this.state}
                    setPageState={(state) => this.setState(state)}
                    buttons={this.renderButtons()}
                    sortableIncomeColumn={this.state.sortableIncomeColumn}
                    sortableIncomeDirection={this.state.sortableIncomeDirection}
                    setIncomeSorting={this.setIncomeSorting}
                    searchValue={this.state.footerSearchValue}
                    setFilteredIncomesAmount={this.setFilteredIncomesAmount}
                />;
            default:
                return <></>

        }
    };

    renderButtons = () => {

        return (
            <div style={{width: "100%"}}>
                <div className="circle-btn-group" >
                    <button className="circle-btn" title="Сохранить">
                        <i className="ti ti-save" onClick={() => this.saveOffer(NEW_OFFER_IDS.includes(this.state.offer?.offerId) && this.afterCreateOfferCallback)}/>
                    </button>
                    <button className="circle-btn" title="Отменить изменения"><i className="ti ti-close"
                                                                                 onClick={this.redirectToOffers}/>
                    </button>
                </div>
            </div>
        )
    };

    renderAdditionalButtons = () => {
        const {offer} = this.state;
        return (
            offer && !NEW_OFFER_IDS.includes(offer.offerId)  &&
            <div className="i-buttons">
                <ActionPopover
                    button={<i
                        className={offer.status === "ACTIVE" ? "ti ti-plug offer-buttons active" : "ti ti-plug offer-buttons"}
                        title="Подключить/Отключить"/>}
                    popoverLocation='left'
                    label={offer.status === "ACTIVE" ? 'Отключить оффер?' : 'Подключить оффер?'}
                    handleSubmit={() => offer.status === "ACTIVE" ? this.deactivate() : (offer.status === "INACTIVE" || offer.status === "DEPRECATED") ? this.activate() : null}
                    disabled={offer.status === "DELETED"}/>
                <ActionPopover
                    button={<i
                        className={offer.status === "DEPRECATED" ? "ti ti-time offer-buttons active" : "ti ti-time offer-buttons"}
                        title="Назначить устаревшим"/>}
                    popoverLocation='left'
                    label='Назначить устаревшим?'
                    handleSubmit={(values) => this.deprecate(values.dateValue && getIsoLocalString(values.dateValue))}
                    hasDatePicker
                    datePickerLabel='Устаревший с даты'
                    disabled={offer.status === "DEPRECATED" || offer.status === "DELETED"}/>
                <ActionPopover
                    button={<i
                        className={offer.status === "DELETED" ? "ti ti-trash offer-buttons active" : "ti ti-trash offer-buttons"}
                        title="Удалить"/>}
                    popoverLocation='left'
                    label='Удалить оффер?'
                    handleSubmit={() => this.deleteOffer()}
                    disabled={offer.status === "DELETED"}/>
                <i className={NEW_OFFER_IDS.includes(offer.offerId) ? "ti ti-files offer-buttons active" : "ti ti-files offer-buttons"}
                   title="Дублировать оффер" onClick={this.cloneOffer}/>
            </div>
        );
    };


    render() {
        const {selectedTab, incomes, filteredIncomesAmount} = this.state;

        let tabs = NEW_OFFER_IDS.includes(this.state.offer?.offerId) ? offerEditTabsWithoutIncomes : offerEditTabs;

        return (
            <>
                {
                    <BasePage
                            pageName={NEW_OFFER_IDS.includes(this.state.offer?.offerId) ? "Новый оффер" : "Редактирование оффера"}
                            content={
                                <PageContent
                                    displayContentHeader
                                    tabs={tabs}
                                    selectedTabName={selectedTab}
                                    onSelectTab={this.selectTab}
                                    contentEl={this.renderContentEl()}
                                    headerAdditionalEl={selectedTab === OFFER_EDIT_MAIN && this.renderAdditionalButtons()}
                                    displaySearchInput={selectedTab === OFFER_EDIT_INCOMES}
                                    onChangeSearchInput={this.changeSearchValue}
                                    searchInputAmount={filteredIncomesAmount}
                                    beforeSearchInputAmount={incomes?.length}
                                />
                            }/>
                }
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        offerCardData: state.managerOffersReducer.offerCardData,
        children: state.managerOffersReducer.children,
    }
};

export default connect(mapStateToProps, {
    loadOffer, loadChildren, loadCategories, loadSubscriptionServices, loadPartnersFilterData, deleteIncome, showWarning,
    activate, deactivate, deprecate, deleteOffer, saveOffer, addRetentionEvent, loadActualCurrencyRates, loadWithChildrenOfferIncomes, loadOfferIncomes, loadAllManagerOffers
})(OfferEditPage);