import React from "react";
import OperatorButton from "../../../common/buttons/OperatorButton";

export default function DashboardOperatorsFilter({operators = [], selectOperator, selectedOperatorTypes = [], className}) {

    return (
        <div className={className}>
                        {
                            operators.map(operator =>
                                <OperatorButton key={operator.operatorType} isSelected={selectedOperatorTypes.includes(operator.operatorType)}
                                                operatorType={operator.operatorType} title={operator.name}
                                                onClick={() => selectOperator(operator)}/>)
                        }
        </div>
    )
}
