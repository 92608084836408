import React, {useState} from "react";
import image_not_available from "../../../../resources/images/image-not-available.png";
import {Modal, ModalBody } from "reactstrap";

export default function OfferItemImg({imgUrl, className = "screen cont", isMobile}) {

    const [isOpen, setIsOpen] = useState(false);
    const [isImgNotFound, setIsImgNotFound] = useState(false);

    const handleImgNotFound = (e) => {
        e.target.src=image_not_available;
        setIsImgNotFound(true);
    };

    return (
        <>
            <div className="single-offer-preview" onClick={() => !isImgNotFound && setIsOpen(!isOpen)}>
                <img className={className} src={imgUrl ? imgUrl : image_not_available} alt="" onError={handleImgNotFound}/>
            </div>

            <Modal centered size={isMobile ? 'sm' : 'md'} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} >
                <ModalBody>
                    <img className="" src={imgUrl} style={{maxHeight: (isMobile ? '80vh' : '90vh'), display: 'block', margin: 'auto'}}
                         onClick={() => setIsOpen(!isOpen)}/>
                </ModalBody>

            </Modal>
        </>
    )
}