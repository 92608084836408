import operator_beeline from "../resources/images/operators/beeline.png";
import operator_mts from "../resources/images/operators/mts.png";
import operator_megafon from "../resources/images/operators/megafon.png";
import operator_tele2 from "../resources/images/operators/tele2.png";
import operator_az from "../resources/images/operators/AZ.png";
import operator_by from "../resources/images/operators/BY.png";
import operator_kz from "../resources/images/operators/KZ.png";
import operator_ua from "../resources/images/operators/UA.png";
import operator_uz from "../resources/images/operators/UZ.png";
import operator_tj from "../resources/images/operators/TJ.png";
import operator_unknown from "../resources/images/operators/UNKNOWN.png";
import {ALL, AZ, BEELINE, BY, KZ, MEGAFON, MTS, TELE_2, UA} from "./constants";

export const OPERATOR_ALL = { label: 'Все', value: ALL, isAllValue: true};
export const OPERATOR_BEELINE = { label: 'Билайн', value: BEELINE, img: operator_beeline};
export const OPERATOR_MTS = { label: 'МТС', value: MTS, img: operator_mts};
export const OPERATOR_MEGAFON = { label: 'Мегафон', value: MEGAFON, img: operator_megafon};
export const OPERATOR_TELE_2 = { label: 'Теле2', value: TELE_2, img: operator_tele2};
export const OPERATOR_AZ = { label: 'Азербайджан', value: AZ, img: operator_az};
export const OPERATOR_BY = { label: 'Беларусь', value: BY, img: operator_by};
export const OPERATOR_KZ = { label: 'Казахстан', value: KZ, img: operator_kz};
export const OPERATOR_UA = { label: 'Украина', value: UA, img: operator_ua};

export const operatorsImages = {
    BEELINE: operator_beeline,
    MTS: operator_mts,
    MEGAFON: operator_megafon,
    TELE_2: operator_tele2,
    AZ: operator_az,
    BY: operator_by,
    KZ: operator_kz,
    UA: operator_ua,
    UZ: operator_uz,
    TJ: operator_tj,
};

const countryNames = {
    AZ: "Азербайджан",
    BY: "Беларусь",
    KZ: "Казахстан",
    RU: "Россия",
    TJ: "Таджикистан",
    UA: "Украина",
    UZ: "Узбекистан",
};

export function getOperatorImg(operatorType){
    return operatorsImages[operatorType] ? operatorsImages[operatorType] : operator_unknown;
}

export function getCountryName(countryType) {
    return countryNames[countryType];
}

